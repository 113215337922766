import React, {Suspense, useState} from 'react';
import {useTranslation} from "react-i18next";
import {AnimatePresence, motion} from 'framer-motion';
import {TOOLS_INFO} from "../../lib/tools"

function Tools() {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
// Lazy load AIToolsCard component
  const AIToolsCard = React.lazy(() => import("../../partials/ecommerce/AIToolsCard"));

  const tools_list = TOOLS_INFO;

  const [filteredTools, setFilteredTools] = useState(tools_list);
  const [selectedCategory, setSelectedCategory] = useState("View All");

  const filterTools = (category) => {
    setSelectedCategory(category);
    if (category === "View All") {
      setFilteredTools(tools_list);
    } else {
      setFilteredTools(tools_list.filter(tool => tool.category === category));
    }
  };

  return (
    <div className="flex overflow-hidden">
      <div className="relative flex flex-col flex-1 overflow-x-hidden">
        <main className="grow">
          <div className="px-0 sm:px-0 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="mb-2">
              <h1 className="md:text-3xl text-gray-800 dark:text-gray-100 font-bold">
                {t('tools.title.title')}
              </h1>
            </div>

            <div className="mb-4 border-b border-gray-200 dark:border-gray-700/60">
              <ul
                className="text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
                {['View All', 'Marketing', 'Sales', 'Business', 'Miscellaneous'].map(category => (
                  <li key={category}
                      className="pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
                    <a
                      className={`whitespace-nowrap ${selectedCategory === category ? 'text-violet-500 border-b-2 border-purple-500 pb-3' : 'text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300'}`}
                      href="#0"
                      onClick={() => filterTools(category)}
                    >
                      {t(`content_filter.${category}`)}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <div className="mt-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                  <Suspense fallback={
                    <div className="flex justify-center items-center w-full h-full">
                      <div className="w-16 h-16 rounded-full shadow-lg animate-pulse bg-gray-300"></div>
                    </div>
                  }>
                    <AnimatePresence>
                      {filteredTools.map((tool, index) => (
                        <motion.div
                          key={index}
                          initial={{opacity: 0, scale: 0.9}}
                          animate={{opacity: 1, scale: 1}}
                          exit={{opacity: 0, scale: 0.9}}
                          transition={{duration: 0.7}}
                          className="w-full h-full"
                        >
                          <AIToolsCard
                            image={tool.image}
                            popular={tool.popular}
                            title={t(`tools.${tool.key}.title`)}
                            description={t(`tools.${tool.key}.description`)}
                            stars={tool.stars}
                            stars_qtd={tool.stars_qtd}
                            price={tool.price}
                            price_normal={tool.price_normal}
                            url={tool.url}
                            slug={tool.slug}
                            className="w-full h-64"
                          />
                        </motion.div>
                      ))}
                    </AnimatePresence>
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Tools;
import React, {useState} from "react";

const Tip = ({icon = 'ℹ️', text = 'This is an example tip!', position = 'top'}) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  // Handle touch devices
  const handleTouch = (e) => {
    e.preventDefault();
    setTooltipVisible(!isTooltipVisible);
  };

  return (
    <div className="relative inline-flex items-center">
      <span
        className="ml-2 text-sm cursor-pointer select-none touch-manipulation"
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
        onClick={handleTouch}
        onTouchStart={handleTouch}
        role="button"
        tabIndex={0}
        aria-label="Show tip"
      >
        {icon}
      </span>

      <div
        className={`
          absolute z-50 
          left-1/2 transform -translate-x-1/2
          w-48 sm:w-64 md:w-80
          p-2 sm:p-3
          text-xs sm:text-sm
          text-white 
          bg-gray-800 dark:bg-gray-700
          rounded-md shadow-lg
          transition-opacity duration-200
          ${position === 'top' ? 'bottom-full mb-2' : 'top-full mt-2'}
          ${isTooltipVisible
          ? 'opacity-100 visible'
          : 'opacity-0 invisible pointer-events-none'
        }
        `}
        role="tooltip"
      >
        <div className="relative">
          {/* Arrow */}
          <div
            className={`
              absolute left-1/2 transform -translate-x-1/2
              w-0 h-0 
              border-solid
              border-4 sm:border-6
              ${position === 'top'
              ? 'border-t-gray-800 dark:border-t-gray-700 border-b-transparent border-x-transparent bottom-0 -mb-2'
              : 'border-b-gray-800 dark:border-b-gray-700 border-t-transparent border-x-transparent top-0 -mt-2'
            }
            `}
          />

          {/* Content */}
          <div className="break-words">
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tip;
// ProgressBar.js
import React from "react";

function ProgressBar({steps, currentStep, onStepChange}) {
  return (
    <div className="sm:px-4 pt-12 pb-8">
      <div className="max-w-xl mx-auto w-full">
        <div className="relative">
          <div
            className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-gray-200 dark:bg-gray-700/60"
            aria-hidden="true"
          ></div>
          <ul className="relative flex justify-between w-full">
            {steps.map((step, index) => (
              <li key={index}>
                <button
                  className={`flex items-center justify-center w-6 h-6 rounded-full text-lg font-semibold ${
                    index <= currentStep
                      ? "bg-violet-500 text-white"
                      : "bg-white dark:bg-gray-900 text-gray-500 dark:text-gray-400"
                  }`}
                  onClick={() => onStepChange(index)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;

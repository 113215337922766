import React, {useEffect} from 'react';
import {useAuth} from "../../util/auth";
import log from "../../util/logger";
import {
  getFullLanguageName,
  getLocalStorageData,
  removeCodeBlockMarksFromMarkdown,
  removeImageTagFromMarkdown
} from "../../util/util";
import {useTranslation} from "react-i18next";
import {useAIDAFramework, useKeywordsAnalysis, usePostIdeas, useWebsiteReport} from "../../util/hooks";
import AccordionBasic from '../AccordionBasic';
import MarkdownRenderer from "../MarkdownRenderer";
import LoadingAnimation from "../LoadingAnimation";

function CompanyReport(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
    setCurrentLanguage(getFullLanguageName(i18n.language))
  };

  const auth = useAuth();

  const [enableCompanyReport, setEnableCompanyReport] = React.useState(false);
  const [currentLanguage, setCurrentLanguage] = React.useState(getFullLanguageName(i18n.language));
  const company_details = getLocalStorageData(`websiteData-${auth.defaultCompany.website_url}`);
  const [enablePostIdeas, setEnablePostIdeas] = React.useState(false);

  log.debug(`Local generatedData-${props.CompanyWebsite}`);
  const companyDataGenerated = getLocalStorageData(`generatedData-${props.CompanyWebsite}`);

  useEffect(() => {
    log.debug(">>>>>>> websiteAnalysisIsSuccess", websiteAnalysisIsSuccess);
    setCurrentLanguage(getFullLanguageName(i18n.language))
    if (auth.defaultCompany.website_url) {
      setEnableCompanyReport(true);
    }
  }, []);


  // ############## Generate AI Website report ##############
  // const company_details = getLocalStorageData(`websiteData-${auth.defaultCompany.website_url}`);
  const {
    data: websiteAnalysisData,
    isLoading: websiteAnalysisIsLoading,
    error: websiteAnalysisError,
    isSuccess: websiteAnalysisIsSuccess,
  } = useWebsiteReport(
    {
      ...auth.defaultCompany,
      location: `"${auth.defaultCompany.city}, ${auth.defaultCompany.postalCode}, 
      ${auth.defaultCompany.street}, ${auth.defaultCompany.country}"`,
      Website_Details: company_details?.original_response?.data?.markdown ? removeImageTagFromMarkdown(company_details?.original_response?.data?.markdown) : ''
    },
    getFullLanguageName(i18n.language),
    (resp) => { /* onSuccess callback */
      log.debug("useWebsiteReport Success: ", resp.data.data);
      // const sanitizedData = JSON.stringify(resp.data.data).replace(/\\n/g, '\n');
    },
    (err) => { /* onError callback */
      log.error("useWebsiteReport error", err);
    },
    true // enabled parameter to control if the query runs
  );

  // ############## AI KEYWORDS ANALYSIS ##############
  log.debug(">>>>>>> websiteAnalysisIsSuccess", websiteAnalysisIsSuccess);
  const {
    data: keywordsAnalysisData,
    isLoading: keywordsAnalysisIsLoading,
    error: keywordsAnalysisIsError,
    isSuccess: keywordsAnalysisIsSuccess,
  } = useKeywordsAnalysis(
    {
      ...auth.defaultCompany,
      location: `"${auth.defaultCompany.city}, ${auth.defaultCompany.postalCode}, 
      ${auth.defaultCompany.street}, ${auth.defaultCompany.country}"`,
      Website_Details: company_details?.original_response?.data?.markdown ? removeImageTagFromMarkdown(company_details?.original_response?.data?.markdown) : ''
    },
    getFullLanguageName(i18n.language),
    (resp) => { /* onSuccess callback */
      log.debug("useKeywordsAnalysis Success");
    },
    (err) => { /* onError callback */
      log.error("useKeywordsAnalysis error", err);
    },
    false, // isSuccess flag (unused)
    websiteAnalysisIsSuccess
  );


  log.debug(">>>>>>> keywordsAnalysisIsSuccess", keywordsAnalysisIsSuccess);
  // ############## AI IDEA GENERATOR ##############
  const {
    data: postIdeasData,
    isLoading: postIdeasIsLoading,
    error: postIdeasIsError,
    isSuccess: postIdeasIsSuccess,
  } = usePostIdeas(
    {
      ...auth.defaultCompany,
      Keywords: keywordsAnalysisData?.data?.data,
      location: `"${auth.defaultCompany.city}, ${auth.defaultCompany.postalCode},
      ${auth.defaultCompany.street}, ${auth.defaultCompany.country}"`,
      Website_Details: company_details?.original_response?.data?.markdown ? removeImageTagFromMarkdown(company_details?.original_response?.data?.markdown) : ''
    },
    getFullLanguageName(i18n.language),
    (resp) => { /* onSuccess callback */
      log.debug("usePostIdeas Success");
    },
    (err) => { /* onError callback */
      log.error("usePostIdeas error", err);
    },
    false, // isSuccess flag (unused)
    keywordsAnalysisIsSuccess
  );

  log.debug(">>>>>>> keywordsAnalysisIsSuccess", keywordsAnalysisIsSuccess);
  // ############## AI AIDA FRAMEWORK ##############
  const {
    data: aidaFrameworkData,
    isLoading: aidaFrameworkIsLoading,
    error: aidaFrameworkIsError,
    isSuccess: aidaFrameworkIsSuccess,
  } = useAIDAFramework(
    {
      ...auth.defaultCompany,
      Keywords: keywordsAnalysisData?.data?.data,
      location: `"${auth.defaultCompany.city}, ${auth.defaultCompany.postalCode},
      ${auth.defaultCompany.street}, ${auth.defaultCompany.country}"`,
      Website_Details: company_details?.original_response?.data?.markdown ? removeImageTagFromMarkdown(company_details?.original_response?.data?.markdown) : ''
    },
    getFullLanguageName(i18n.language),
    (resp) => { /* onSuccess callback */
      log.debug("usePostIdeas Success");
    },
    (err) => { /* onError callback */
      log.error("usePostIdeas error", err);
    },
    false, // isSuccess flag (unused)
    postIdeasIsSuccess
  );


  return (
    <div className="flex h-[100dvh] overflow-hidden">
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main className="grow">
          <div className="px-0 sm:px-4 lg:px-8 py-2 w-full">
            {/* Page content */}
            <div className="max-w-5xl sm:w-full mx-auto flex flex-col lg:flex-row lg:space-x-8 xl:space-x-16">
              <div className="w-full">
                <header className="mb-3">
                  {/* Title */}
                  <div className="flex flex-col items-start">
                    <h1 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 font-bold mb-2">
                      {t("dashboard.report.title")} - {auth.defaultCompany.name}
                    </h1>
                    <span className="text text-gray-800 dark:text-gray-100">
                    {t("dashboard.report.subtitle")}
                  </span>
                    <span className="text-sm text-right mt-2">
                      {new Date().toLocaleDateString(i18n.language, {month: 'short', day: '2-digit', year: 'numeric'})}
                      {/*{websiteAnalysisData.}*/}
                    </span>
                  </div>
                </header>


                {websiteAnalysisIsLoading && !websiteAnalysisData && (
                  <LoadingAnimation text={"✨" + t("dashboard.loading_ai_content")}/>
                )}
                {websiteAnalysisData && websiteAnalysisIsSuccess && (
                  <div className="w-full mb-6">
                    {/*<h2 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-6">Website Analysis</h2>*/}
                    <AccordionBasic title={t("dashboard.report.business_analysis")}>
                      {/*<div className="h4">Website Analysis by AI</div>*/}
                      {/*<div className="mb-6"> {websiteAnalysisData} </div>*/}
                      <MarkdownRenderer
                        content={removeCodeBlockMarksFromMarkdown(JSON.stringify(websiteAnalysisData.data.data).slice(1, -1).replace(/\\n/g, '\n'))}/>
                    </AccordionBasic>
                  </div>
                )}


                {keywordsAnalysisIsLoading && !keywordsAnalysisData && (
                  <LoadingAnimation text={"✨" + t("dashboard.loading_ai_content")}/>
                )}
                {keywordsAnalysisIsSuccess && keywordsAnalysisData && (
                  <div className="w-full mb-6">
                    {/*<h2 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-6">Keywords Study</h2>*/}
                    <AccordionBasic title={t("dashboard.report.keywords_study")}>
                      {/*<div className="h4">Website Analysis by AI</div>*/}
                      {/*<div className="mb-6"> {websiteAnalysisData} </div>*/}
                      <MarkdownRenderer
                        content={removeCodeBlockMarksFromMarkdown(JSON.stringify(keywordsAnalysisData.data.data).slice(1, -1).replace(/\\n/g, '\n'))}/>
                    </AccordionBasic>
                  </div>
                )}


                {postIdeasIsLoading && (
                  <LoadingAnimation text={"✨" + t("dashboard.loading_ai_content")}/>
                )}
                {postIdeasIsSuccess && (
                  <div className="w-full mb-6">
                    {/*<h2 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-6">Keywords Study</h2>*/}
                    <AccordionBasic title={t("dashboard.report.post_ideas")}>
                      {/*<div className="h4">Website Analysis by AI</div>*/}
                      <div className="mb-6 ">{t("dashboard.report.ideas_description")}</div>
                      <MarkdownRenderer
                        content={removeCodeBlockMarksFromMarkdown(JSON.stringify(postIdeasData.data.data).slice(1, -1).replace(/\\n/g, '\n'))}/>
                    </AccordionBasic>
                  </div>
                )}


                {aidaFrameworkIsLoading && (
                  <LoadingAnimation text={"✨" + t("dashboard.loading_ai_content")}/>
                )}
                {aidaFrameworkIsSuccess && (
                  <div className="w-full mb-6">
                    {/*<h2 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-6">Keywords Study</h2>*/}
                    <AccordionBasic title={t("dashboard.report.aida_framework")}>
                      {/*<div className="h4">Website Analysis by AI</div>*/}
                      <div className="mb-6 ">{t("dashboard.report.aida_framework_description")}</div>
                      <MarkdownRenderer
                        content={removeCodeBlockMarksFromMarkdown(JSON.stringify(aidaFrameworkData.data.data).slice(1, -1).replace(/\\n/g, '\n'))}/>
                    </AccordionBasic>
                  </div>
                )}


                {/*/!* WEBSITE ANALYSIS *!/*/}
                {/*<div>*/}
                {/*  <ReportWebsiteAnalysis websiteAnalysis={websiteAnalysis}/>*/}
                {/*</div>*/}

                {/*<hr className="my-6 border-t border-gray-100 dark:border-gray-700/60"/>*/}

                {/*/!* SEO *!/*/}
                {/*<h1 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 font-bold mb-2">*/}
                {/*  SEO*/}
                {/*</h1>*/}
                {/*<div>*/}
                {/*  <ReportWebsiteAnalysis websiteAnalysis={SEO}/>*/}
                {/*</div>*/}

              </div>

            </div>

          </div>
        </main>

      </div>

    </div>
  );
}

export default CompanyReport;
// Onboarding01.js
import React, {useEffect, useRef, useState} from "react";
import Header from "./Header";
import ProgressBar from "./ProgressBar";
import ImageSection from "./ImageSection";
import {FormProvider, useFormContext} from "../../util/FormContext";
import log from "./../../util/logger";


import Step1Form from "./Step1Form";
import Step2Form from "./Step2Form";
import Step3Form from "./Step3form";
import Step4Form from "./Step4form";
import {motion} from 'framer-motion';

import {useHistory} from "react-router-dom";

import PartyEffect from "../PartyEffect";
import {useTranslation} from "react-i18next";

function Onboarding01() {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);
  const [previousStep, setPreviousStep] = useState(0);
  const delta = currentStep - previousStep;
  const [formErrors, setFormErrors] = useState({});
  const stepRefs = [useRef(), useRef(), useRef(), useRef()];
  // const step2FormRef = useRef();
  const {formData} = useFormContext();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleNext();
      }
    };

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [currentStep]);


  const steps = [
    {label: "Step 1", path: "/onboarding-01", component: <Step1Form ref={stepRefs[0]} setFormErrors={setFormErrors}/>},
    {label: "Step 2", path: "/onboarding-02", component: <Step2Form ref={stepRefs[1]} setFormErrors={setFormErrors}/>},
    {label: "Step 3", path: "/onboarding-03", component: <Step3Form ref={stepRefs[2]} setFormErrors={setFormErrors}/>},
    {label: "Step 4", path: "/onboarding-04", component: <Step4Form ref={stepRefs[3]} setFormErrors={setFormErrors}/>},
  ];

  const handleNext = () => {
    log.debug("handleNext currentStep ", currentStep, " delta ", delta, " steps.length ", steps.length);
    if (currentStep < steps.length - 1) {
      const isValid = stepRefs[currentStep].current.validateForm();
      if (isValid) {
        setPreviousStep(currentStep);
        setCurrentStep(currentStep + 1);
      } else {
        log.debug("handleNext currentStep ", currentStep, " delta ", delta, " steps.length ", steps.length, " isValid ", isValid);
      }
    }
  };

  const handleBack = () => {
    log.debug("handleBack currentStep ", currentStep, " delta ", delta);
    if (currentStep > 0) {
      setPreviousStep(currentStep);
      setCurrentStep(currentStep - 1);
    }
  };

  const handleStepChange = (index) => {
    log.debug("handleStepChange index ", index);
    setPreviousStep(currentStep);
    // Go to back step
    if ((currentStep > 0) && (index < currentStep)) {
      handleBack()
    }
    // Go to next step
    else if ((currentStep < steps.length - 1) && (index > currentStep)) {
      handleNext()
    }
  };

  return (
    <FormProvider>
      <main className="bg-white dark:bg-gray-900">
        <div className="relative flex">
          <div className="w-full md:w-2/3">
            <div className="min-h-[70dvh] h-full flex flex-col after:flex-1">
              <div className="flex-1">
                <Header/>
                <ProgressBar steps={steps} currentStep={currentStep} onStepChange={handleStepChange}/>
              </div>

              <motion.div
                key={currentStep}
                initial={{x: delta >= 0 ? '50%' : '-50%', opacity: 0}}
                animate={{x: 0, opacity: 1}}
                transition={{duration: 0.4, ease: 'easeInOut'}}
              >

                {/*- - - - - - - - - - - - - - */}
                {/* RENDER MAIN COMPONENT HERE */}
                {steps[currentStep].component}

              </motion.div>

              {/* Buttons */}
              <div className={currentStep === steps.length - 1
                ? "flex justify-center max-w-xl mx-auto w-full"
                : "flex justify-between max-w-xl mx-auto w-full"
              }>
                <button
                  onClick={handleBack}
                  className={currentStep === steps.length - 1
                    ? 'hidden'
                    : "btn bg-gray-900 text-gray-100 hover:bg-gray-800 dark:bg-gray-100 dark:text-gray-800 dark:hover:bg-white"
                  }
                  disabled={currentStep === 0}
                >
                  &lt;- {t('onboarding.back')}
                </button>

                <button
                  onClick={currentStep === steps.length - 1 ? () => history.push('/dashboard', {state: {formData}}) : handleNext}
                  className={`btn ${currentStep === steps.length - 1
                    ? 'bg-gray-900 text-gray-100 hover:bg-gray-800 dark:bg-green-500 dark:text-gray-800 dark:hover:bg-green-300'
                    : 'bg-gray-900 hover:bg-green-600'} text-gray-100 dark:bg-gray-100 dark:text-gray-800 dark:hover:bg-white`}
                >
                  {currentStep === steps.length - 1 ? t('onboarding.goToDashboard') : t('onboarding.next')} -&gt;
                </button>
              </div>
            </div>
          </div>
          <ImageSection/>
        </div>
        {currentStep === 3 && <PartyEffect/>}
      </main>
    </FormProvider>
  );
}

export default Onboarding01;

import React from "react";
import Meta from "./../components/Meta";
import {requireAuth} from "./../util/auth";
import {useTranslation} from "react-i18next";
import ToolsSection from "../components/ToolsSection";

function ToolsPage(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  return (
    <>
      <Meta title="Tools"/>
      <ToolsSection
        title={t("Tools_page_title")}
        subtitle="" //{t("Dashboard_page_description")}
        strapline=""
        size="md"
        bgColor=""
        {...props}
      />
    </>
  );
}

export default requireAuth(ToolsPage);

import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import {useAuth} from "./../util/auth";
import Tools from "./Tools/Tools";

function ContentSection(props) {
  const auth = useAuth();

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />

        {/*<Divider>Total posts from your connected accounts</Divider>*/}
        {/*<SectionItem title="Overview">*/}
        {/*  <div className="prose prose-indigo dark:text-gray-300">*/}
        {/*    <p>Here you can see the overview of your account</p>*/}
        {/*  </div>*/}
        {/*</SectionItem>*/}

        <div className="relative flex flex-col flex-1 overflow-auto">
          <Tools/>
        </div>

        {/*-----------------*/}
        {/*<SectionItem title="AI-Powered Content Generator">*/}
        {/*  <div className="mx-8 md:mx-16  xl:mx-40 2xl:mx-60">*/}
        {/*    <ul className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4">*/}
        {/*      {TOOLS_INFO.map((tool, idx) => (*/}
        {/*        <li key={idx} className="w-full">*/}
        {/*          <Card*/}
        {/*            title={tool.title}*/}
        {/*            description={tool.description}*/}
        {/*            icon={tool.icon}*/}
        {/*            tip={tool.copywriting}*/}
        {/*            // Setting a fixed size to ensure all cards have the same size*/}
        {/*            className="min-h-[250px] h-full"*/}
        {/*          />*/}
        {/*        </li>*/}
        {/*      ))}*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</SectionItem>*/}

      </div>
    </Section>
  );
}

export default ContentSection;

import log from 'loglevel';

// Log level
const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL || 'ERROR';
log.setDefaultLevel(LOG_LEVEL);

// Get current log level
const currentLogLevel = Object.keys(log.levels).find(key => log.levels[key] === log.getLevel());
console.log('Current log level:', currentLogLevel);

export default log;

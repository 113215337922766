import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import {useTranslation} from "react-i18next";

function FaqSection(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  const faqItems = [
    {
      question: t("faq.free_trial.question"),
      answer: t("faq.free_trial.answer")
    },
    {
      question: t("faq.cancel_subscription_anytime.question"),
      answer: t("faq.cancel_subscription_anytime.answer")
    },
    {
      question: t("faq.updates_free_for_life.question"),
      answer: t("faq.updates_free_for_life.answer")
    },
    {
      question: t("faq.manage_brands_pro_plan.question"),
      answer: t("faq.manage_brands_pro_plan.answer")
    },
    {
      question: t("faq.support_expectations.question"),
      answer: t("faq.support_expectations.answer")
    },
    {
      question: t("faq.ai_content_updates.question"),
      answer: t("faq.ai_content_updates.answer")
    },
    {
      question: t("faq.upgrade_downgrade_plan.question"),
      answer: t("faq.upgrade_downgrade_plan.answer")
    },
    {
      question: t("faq.training_new_users.question"),
      answer: t("faq.training_new_users.answer")
    },
    {
      question: t("faq.multi_platform_management.question"),
      answer: t("faq.multi_platform_management.answer")
    },
    {
      question: t("faq.competitor_insights.question"),
      answer: t("faq.competitor_insights.answer")
    },
    {
      question: t("faq.post_scheduling_limit.question"),
      answer: t("faq.post_scheduling_limit.answer")
    },
    {
      question: t("faq.still_have_question.question"),
      answer: t("faq.still_have_question.answer")
    }
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-12 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
          {faqItems.map((item, index) => (
            <div className="prose prose-indigo text-gray-600 dark:text-dark border-2 border-gray-700 p-2" key={index}>
              <h4 className="text-gray-600 dark:text-dark">{item.question}</h4>
              <p className="text-gray-600 dark:text-gray-400">{item.answer}</p>
            </div>
          ))}
        </div>

        {/*{props.showSupportButton && (*/}
        {/*  <div className="text-center">*/}
        {/*    <Button*/}
        {/*      href={props.supportUrl}*/}
        {/*      target="_blank"*/}
        {/*      size="lg"*/}
        {/*      variant="simple"*/}
        {/*      startIcon={*/}
        {/*        <ArrowTopRightOnSquareIcon className="opacity-50 inline-block w-5 h-5"/>*/}
        {/*      }*/}
        {/*    >*/}
        {/*      Go to support center*/}
        {/*    </Button>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </Section>
  );
}

export default FaqSection;

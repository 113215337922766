import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useFormContext} from '../../util/FormContext';
import {useTranslation} from "react-i18next";
import {AnimatePresence, motion} from 'framer-motion';
import {checkIsValidURL, urlClean} from "../../util/util";

import log from "../../util/logger";

const Step1Form = forwardRef(({setFormErrors}, ref) => {
  log.debug("------ Step1Form.js: Step1Form 1 -------")
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  const {formData, updateFormData} = useFormContext();
  const {isWebsiteChecked, setIsWebsiteChecked} = useFormContext()

  const [selectedOption, setSelectedOption] = useState(formData.option || 'website');
  const [website, setWebsite] = useState(formData.website || '');
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [isWebsiteAvailable, setIsWebsiteAvailable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const debounceTimeout = useRef(null); // useRef to store the timeout ID

  const inputRef = useRef(null);

  const [formFields, setFormFields] = useState({
    option: formData.option || 'website',
    website: formData.website || '',
    isWebsiteChecked: false,
  });

  // Expose validation and submission functions to parent component
  useImperativeHandle(ref, () => ({
    validateForm,
    submitForm: () => {
      if (validateForm()) {
        updateFormData(formFields);
      }
    },
  }));

  useEffect(() => {
    log.debug("formData in Step1Form:", formData);
    updateFormData(formFields);
    window.scrollTo(0, 0);

    if (inputRef.current) {
      inputRef.current.focus();
    }

  }, []);


  const handleOptionChange = (event) => {
    const {value} = event.target;
    // Update the selected option state
    setSelectedOption(value);
    // Update the formData context with the new option value
    updateFormData({option: value});
  };

  const handleWebsiteChange = (event) => {
    const {value} = event.target;
    if (value === '') {
      setWebsite('');
      setIsValidUrl(null);
      setLoading(false);
      return;
    }

    const website = value.trim().toLowerCase();
    setWebsite(website);
    updateFormData({website: website});
    setIsWebsiteChecked(false);
    setIsValidUrl(null);
  };

  // Validation function
  const validateForm = () => {
    let newErrors = '';
    let isValid = true;
    setLoading(true);

    if (!website && selectedOption === 'website') {
      newErrors = "Website URL is required";
      isValid = false;
      log.debug("validateForm: website is required");
    } else if (selectedOption === 'website') {
      if (!checkIsValidURL(website)) {
        newErrors = "Invalid URL format";
        isValid = false;
        log.debug("validateForm: invalid URL format");
      } else {
        updateFormData({website: urlClean(website)});
      }
    }
    // } else if (selectedOption === 'social') {
    //   isValid = true;
    // }

    log.debug("validateForm: isValid ", isValid, " newErrors ", newErrors);

    setFormErrors(newErrors);
    setIsValidUrl(isValid);
    return isValid;
  };


  return (
    <div className="sm:px-4 py-4">
      <div className="max-w-xl mx-auto">
        <h1 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-6">
          {t('onboarding.step1.title')}
        </h1>
        <form>
          <div className="sm:flex space-y-3 sm:space-y-0 sm:space-x-4 mb-8">

            {/* OPTION 1 - WEBSITE */}
            <label className="flex-1 relative block cursor-pointer">
              <input
                type="radio"
                name="radio-buttons"
                value="website"
                className="peer sr-only"
                checked={selectedOption === 'website'}
                onChange={handleOptionChange}
              />
              <div
                className="h-full text-center bg-white dark:bg-gray-800 px-4 py-6 rounded-lg border border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 shadow-sm transition">
                <svg
                  className="inline-flex fill-current text-violet-500 mt-2 mb-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M13 22V11a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v13H0V14a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v8h2Zm6-15h-2V3a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7H5V3a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v4ZM9 22v-8a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v8h7Zm13 0V11a1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1v11h7Zm-5-8v-2h3v2h-3Zm0 3v-2h3v2h-3Zm0 3v-2h3v2h-3ZM4 20v-2h3v2H4Zm0-3v-2h3v2H4Z"
                  />
                </svg>
                <div className="font-semibold text-gray-800 dark:text-gray-100 mb-1">
                  {t("onboarding.step1.organization")}
                </div>
                <div className="text-sm text-violet-300">
                  {t("onboarding.step1.organization_description")}
                </div>
              </div>
              <div
                className="absolute inset-0 border-2 border-transparent peer-checked:border-violet-400 dark:peer-checked:border-violet-500 rounded-lg pointer-events-none"
                aria-hidden="true"
              ></div>
            </label>

            {/* OPTION 2 - JUST SOCIAL */}
            {/*<label className="flex-1 relative block cursor-pointer">*/}
            {/*  <input*/}
            {/*    type="radio"*/}
            {/*    name="radio-buttons"*/}
            {/*    value="social"*/}
            {/*    className="peer sr-only"*/}
            {/*    checked={selectedOption === 'social'}*/}
            {/*    onChange={handleOptionChange}*/}
            {/*  />*/}
            {/*  <div*/}
            {/*    className="h-full text-center bg-white dark:bg-gray-800 px-4 py-6 rounded-lg border border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 shadow-sm transition">*/}
            {/*    <svg*/}
            {/*      className="inline-flex fill-current text-violet-500 mt-2 mb-4"*/}
            {/*      xmlns="http://www.w3.org/2000/svg"*/}
            {/*      width={24}*/}
            {/*      height={24}*/}
            {/*      viewBox="0 0 24 24"*/}
            {/*    >*/}
            {/*      <path*/}
            {/*        d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10a9.955 9.955 0 0 1-2.003 6.005 2 2 0 0 0-1.382-1.115l-3.293-.732-.295-1.178A4.992 4.992 0 0 0 17 11v-1a5 5 0 0 0-10 0v1c0 1.626.776 3.07 1.977 3.983l-.294 1.175-3.293.732a1.999 1.999 0 0 0-1.384 1.119A9.956 9.956 0 0 1 2 12Zm3.61 7.693A9.96 9.96 0 0 0 12 22c2.431 0 4.66-.868 6.393-2.31l-.212-.847-4.5-1-.496-1.984a5.016 5.016 0 0 1-2.365 0l-.496 1.983-4.5 1-.213.85ZM12 7a3 3 0 0 0-3 3v1a3 3 0 1 0 6 0v-1a3 3 0 0 0-3-3Z"*/}
            {/*        fillRule="evenodd"*/}
            {/*      />*/}
            {/*    </svg>*/}
            {/*    <div className="font-semibold text-gray-800 dark:text-gray-100 mb-1">*/}
            {/*      {t("onboarding.step1.personal")}*/}
            {/*    </div>*/}
            {/*    <div className="text-sm text-violet-300">*/}
            {/*      {t("onboarding.step1.personal_description")}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div*/}
            {/*    className="absolute inset-0 border-2 border-transparent peer-checked:border-violet-400 dark:peer-checked:border-violet-500 rounded-lg pointer-events-none"*/}
            {/*    aria-hidden="true"*/}
            {/*  ></div>*/}
            {/*</label>*/}
          </div>

          {/* Website Field - Placed Below the Options */}


          <AnimatePresence>
            {selectedOption === 'website' && (
              <motion.div
                className="mt-3"
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: 20}}
                transition={{duration: 0.6}}
              >
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Website URL
                </label>
                <input
                  ref={inputRef}
                  type="text"
                  className="mt-2 block w-full px-4 py-3 text-xl font-medium shadow-md shadow-purple-200 border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-gray-100 dark:border-gray-600 transition-all duration-200"
                  placeholder="www.your-website.com"
                  value={website}
                  onChange={handleWebsiteChange}
                  autoComplete="website"
                />

                {(isValidUrl === false) && website && (
                  <motion.p
                    className="text-red-500"
                    initial={{x: -10, color: "#FF0000"}}
                    animate={{x: [0, -5, 5, -5, 5, -5, 5, 0], color: "#FF4040"}}
                    exit={{opacity: 0}}
                    transition={{type: "spring", stiffness: 300, damping: 10, duration: 0.8}}
                  >
                    🚫 {t('onboarding.step1.websiteError')}
                  </motion.p>
                )}

                {loading && website === '' && (
                  <motion.p
                    className="text-red-500"
                    initial={{x: -10, color: "#FF0000"}}
                    animate={{x: [0, -5, 5, -5, 5, -5, 5, 0], color: "#FF4040"}}
                    exit={{opacity: 0}}
                    transition={{type: "spring", stiffness: 300, damping: 10, duration: 0.8}}
                  >🚫 {t('onboarding.step1.websiteError')}
                  </motion.p>)}

                {/*{!loading && isValidUrl === true && isWebsiteAvailable === false && (*/}
                {/*  <p className="text-red-500">❌ Website is not available</p>*/}
                {/*)}*/}
                {/*{!loading && isValidUrl === true && isWebsiteAvailable === true && (*/}
                {/*  <p className="text-green-500">✅ Website is online</p>*/}
                {/*)}*/}
              </motion.div>
            )}
          </AnimatePresence>


        </form>
      </div>
    </div>
  );
});

export default Step1Form;

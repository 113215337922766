import React, {useEffect, useRef, useState} from "react";
import {useAuth} from "../../util/auth";
import {useTranslation} from "react-i18next";
import log from "../../util/logger";
import ContentMixCard from "./ContentMixCard";
import ContentStrategyForm from "./ContentStrategyForm";
import LoadingAnimation from "../LoadingAnimation";
import {GenerateRequestModel, useContentMix} from "../../util/hooks";
import {cleanData, getFullLanguageName, removeLocalStorageData} from "../../util/util";
import {useQueryClient} from "@tanstack/react-query";
import ModalBasic from "../ModalBasic";
import {AnimatePresence} from "framer-motion";
import ContentIdea from "./ContentIdea";
import MarkdownRenderer from "../MarkdownRenderer";
import api from "../../api";

// import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FaRegClipboard} from "react-icons/fa";


function CompanyContent(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  const auth = useAuth();

  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = React.useState(true);
  const [enableContentStrategyForm, setEnableContentStrategyForm] = React.useState(false);
  const [contentStrategy, setContentStrategy] = React.useState(null);
  const [enableContentMix, setEnableContentMix] = React.useState(false);
  const [contentIdeas, setContentIdeas] = React.useState([]);
  const [contentMixDescription, setContentMixDescription] = React.useState("");
  const [enableGetContentMix, setEnableGetContentMix] = React.useState(false);
  const [basicModalOpen, setBasicModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [enablePostGenerator, setEnablePostGenerator] = useState(true);

  const [generatePostData, setGeneratePostData] = useState(null);
  const [generatePostIsLoading, setGeneratePostIsLoading] = useState(false);
  const [generatePostError, setGeneratePostError] = useState(null);
  const [generatePostIsSuccess, setGeneratePostIsSuccess] = useState(false);
  // const [actualIndex.current, setactualIndex.current] = useState(0);
  const [dataCopied, setDataCopied] = useState(false);
  const actualIndex = useRef(0)


  const shareUrl = "https://www.sunokrom.com" // replace with your URL


  let strategyProfile = null

  useEffect(() => {
    if (basicModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [basicModalOpen]);


  const updateModal = (index) => {
    actualIndex.current = index
    log.debug('[CompanyContent] updateModal index received', index);
    log.debug('[CompanyContent] updateModal actual_index ', actualIndex.current);
    log.debug('[CompanyContent] ...contentIdeas[index] ', contentIdeas[actualIndex.current]);
    setModalData(contentIdeas[actualIndex.current]);
    setBasicModalOpen(true);
  }


  const updateStrategy = (strategy) => {
    log.debug('[CompanyContent] Updating Content Strategy: ', strategy);
    setIsLoading(true);
    strategyProfile = strategy;
    setEnableGetContentMix(true);
    // setContentStrategy(strategy);

    // Call API to update the company content strategy
  }

  const removeContentIdea = (index) => {
    setContentIdeas((prevIdeas) => prevIdeas.filter((_, i) => i !== index));
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }


  function hashString(str) {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) + hash + str.charCodeAt(i); // hash * 33 + c
    }
    return hash >>> 0; // Ensure the hash is a positive integer
  }


  // ############## AI CONTENT MIX PULL ##############
  const {
    data: contentMixData,
    isLoading: contentMixIsLoading,
    error: contentMixIsError,
    isSuccess: contentMixIsSuccess,
    refetch: useContentMixRefetch
  } = useContentMix(
    {
      ...auth.defaultCompany,
      content_profile: strategyProfile,
      location: `"${auth.defaultCompany.city}, ${auth.defaultCompany.postalCode}, 
      ${auth.defaultCompany.street}, ${auth.defaultCompany.country}"`,
    },
    getFullLanguageName(i18n.language),
    (resp) => { /* onSuccess callback */
      log.debug("useContentMix Success", resp);


    },
    (err) => { /* onError callback */
      log.error("useContentMix error", err);
    },
    false, // isSuccess flag (unused)
    enableGetContentMix // enable flag
  );

  const getNewPost = async (data) => {
    log.debug("Generating new post");
    log.debug("Content Idea: ", contentIdeas[actualIndex.current]);
    log.debug("getNewPost actual_index : ", actualIndex.current);
    setGeneratePostIsLoading(true);
    const ideaHash = hashString(contentIdeas[actualIndex.current]?.emoji + ' ' + contentIdeas[actualIndex.current]?.idea + ' ' + contentIdeas[actualIndex.current]?.summary)

    const prompt = `
    ## post_idea: ${contentIdeas[actualIndex.current]?.emoji + ' ' + contentIdeas[actualIndex.current]?.idea + ' ' + contentIdeas[actualIndex.current]?.summary},
    ## Business name: ${auth.defaultCompany.name || auth.defaultCompany.companyName || ''}
    ## Business Website: ${auth.defaultCompany.website_url || ''}
    ## Company Headline: ${auth.defaultCompany.description || auth.defaultCompany.companyHeadline || ''}
    ## Company location: "${auth.defaultCompany.city}, ${auth.defaultCompany.postalCode}, ${auth.defaultCompany.street}, ${auth.defaultCompany.country}",
    ## Keywords: ${auth.defaultCompany.keywords || ''}
    ## Business information miscellaneous: ${auth.defaultCompany?.scrape_data || null} 
      ${auth.defaultCompany.street}, ${auth.defaultCompany.country}"`


    const query_name = 'usePostGenerator';
    const requestModel5 = new GenerateRequestModel(prompt, 2000, 'anthropic', auth.defaultCompany.website_url, query_name);

    log.debug("usePostGenerator Prompt: ", prompt);
    log.debug("usePostGenerator Request: ", requestModel5);

    api.fetchGeneratedData2(requestModel5).then((resp) => {
      setGeneratePostIsLoading(false);
      setGeneratePostIsSuccess(true);
      log.debug("usePostGenerator Respose: ", resp.data.data);
      // const sanitizedData = JSON.stringify(resp.data.data).replace(/\\n/g, '\n');
      let data = resp.data.data
      if (typeof resp.data.data === 'object') {
        log.debug("resp.data.data is object");
        data = JSON.stringify(resp.data.data);
      }
      setGeneratePostData(data);
      log.debug("usePostGenerator data: ", data);
    }).catch((err) => {
      setGeneratePostIsLoading(false);
      setGeneratePostIsSuccess(false);
      setGeneratePostError(err);
      log.error("usePostGenerator error", err);
    })
  }

  // ############## Generate POST ##############
  // const company_details = getLocalStorageData(`websiteData-${auth.defaultCompany.website_url}`);
  // const {
  //   data: generatePostData,
  //   isLoading: generatePostIsLoading,
  //   error: generatePostError,
  //   isSuccess: generatePostIsSuccess,
  //   refetch: generatePostRefetch
  // } = usePostGenerator(
  //   {
  //     post_idea: contentIdeas[actual_index]?.emoji + ' ' + contentIdeas[actual_index]?.idea + ' ' + contentIdeas[actual_index]?.summary,
  //     ideaHash: hashString(contentIdeas[actual_index]?.emoji + ' ' + contentIdeas[actual_index]?.idea + ' ' + contentIdeas[actual_index]?.summary),
  //     ...auth.defaultCompany,
  //     location: `"${auth.defaultCompany.city}, ${auth.defaultCompany.postalCode},
  //     ${auth.defaultCompany.street}, ${auth.defaultCompany.country}"`,
  //     Website_Details: auth.defaultCompany?.original_response?.data?.markdown ? removeImageTagFromMarkdown(auth.defaultCompany?.original_response?.data?.markdown) : ''
  //   },
  //   getFullLanguageName(i18n.language),
  //   (resp) => { /* onSuccess callback */
  //     log.debug("useWebsiteReport Success: ", resp.data.data);
  //     // const sanitizedData = JSON.stringify(resp.data.data).replace(/\\n/g, '\n');
  //   },
  //   (err) => { /* onError callback */
  //     log.error("useWebsiteReport error", err);
  //   },
  //   true, // enabled parameter to control if the query runs
  //   enablePostGenerator // enable flag
  // );


  const ContentMixCleanUp = () => {
    log.info("Cleaning up content mix data");
    queryClient.removeQueries(['useContentMix', auth.defaultCompany.website_url]);
    try {
      const clean_localStorage = removeLocalStorageData(`useContentMix-${auth.defaultCompany.website_url}`);
      log.warn("Cleaned Local Storage: ", clean_localStorage)
    } catch (e) {
      log.error("Cleaned Local Storage error ", e)
    }

    log.info("Refetching content mix data");
    // useContentMixRefetch();
  }

  useEffect(() => {
    if (auth.defaultCompany) {
      log.debug('[CompanyContent] Default Company: ', auth.defaultCompany);
      if (auth.defaultCompany.content_mix_profile && auth.defaultCompany.content_mix_profile !== "" && auth.defaultCompany.content_mix_profile !== null && auth.defaultCompany.content_mix_profile !== undefined) {
        log.debug("[CompanyContent] Found content mix profile");
        log.debug('[CompanyContent] Content Strategy: ', auth.defaultCompany.content_mix_profile);
        setContentStrategy(auth.defaultCompany.contentStrategy);
        setEnableContentStrategyForm(false);
        setIsLoading(true);
        setEnableContentMix(false);
        setEnableGetContentMix(true);

      } else {
        log.debug('[CompanyContent] Company Content Strategy not found, loading form');
        setEnableContentStrategyForm(true);
        log.warn("Query removed: ", ['useContentMix', auth.defaultCompany.website_url]);
        ContentMixCleanUp();

        setIsLoading(false);
      }
    } else {
      log.error('[CompanyContent] Default Company not found');
    }

    log.debug("enableContentStrategyForm", enableContentStrategyForm);
    log.debug("enableContentMix", enableContentMix);
    log.debug("contentMixIsSuccess", contentMixIsSuccess);
  }, [])

  useEffect(() => {
    log.debug("contentMixData", contentMixData);
    log.debug("contentMixIsSuccess", contentMixIsSuccess);
    if (contentMixIsSuccess && contentMixData && contentMixData.data && contentMixData.data.content_ideas && contentMixData.data.content_ideas.length > 0) {
      const shuffledContentIdeas = shuffleArray([...contentMixData.data.content_ideas]);
      setContentMixDescription(contentMixData.data.content_mix.description);
      log.debug("contentMixData.data.content_mix.description: ", contentMixData.data.content_mix.description);
      setContentIdeas(shuffledContentIdeas);
      setIsLoading(false)
      setEnableContentMix(true);
    } else {
      log.error("contentMixData No content ideas found");
      ContentMixCleanUp();

    }

  }, [contentMixData, contentMixIsSuccess]);

  useEffect(() => {
    log.debug("Basic Modal Open: ", basicModalOpen);

    if (basicModalOpen) {
      // setGeneratePostData("\"🏠 Pequenos espaços, grandes possibilidades! Você sabia que é possível transformar completamente seu ambiente com móveis planejados inteligentes? \\\\n\\\\n✨ Apresentamos 8 soluções criativas para otimizar cada cantinho da sua casa:\\\\n\\\\n- Camas com gavetas embutidas\\\\n- Mesas retráteis de parede\\\\n- Armários multiuso\\\\n- Nichos organizadores\\\\n- Painéis multifuncionais\\\\n- Bancadas extensíveis\\\\n- Gaveteiros deslizantes\\\\n- Estantes modulares\\\\n\\\\n🎯 Quer conhecer mais sobre essas soluções e ver como podemos transformar seu espaço? Entre em contato conosco! \\\\n\\\\n📞 Agende uma visita: (11) 4067-1911\\\\n⚡ Orçamento sem compromisso!\\\\n\\\\n#MoveisPlayejados #MarcenariaDoElton #DecoracaoInteligente #AmbientesPlanejados\"");
      log.debug("Opening Basic Modal, generatePostData:", generatePostData);
      setDataCopied(false);

      setGeneratePostIsSuccess(false);

      // how execute this in 3 seconds?

      // setGeneratePostIsLoading(true)
      // setTimeout(() => {
      //   log.debug("From timeout Generating new post");
      //   getNewPost();
      // }, 2000);

    } else {
      setGeneratePostData(null);
      setGeneratePostIsLoading(false)
    }

  }, [basicModalOpen]);

  // useEffect(() => {
  //   if (contentStrategy) {
  //     log.debug('[CompanyContent] Content Strategy: ', contentStrategy);
  //     setEnableContentStrategyForm(false);
  //     setIsLoading(false);
  //     setEnableContentMix(true);
  //   } else {
  //     log.debug('[CompanyContent] Content Strategy not found');
  //   }
  // }, [contentStrategy])


  if (isLoading) {
    return (
      <LoadingAnimation text={"✨" + t("dashboard.loading_ai_content")}/>
    )
  }


  return (
    <div className="flex overflow-hidden h-full w-full mb-2">

      {/*<div className="flex-row flex items-center justify-between max-h-8">*/}
      {/*  <ToastContainer theme="dark" />*/}
      {/*</div>*/}

      {enableContentStrategyForm && (
        <ContentStrategyForm updateStrategy={updateStrategy}/>
      )}

      {contentMixIsSuccess && (
        <div className="my-2 h-full w-full">
          <div className="flex flex-wrap max-h-90 overflow-hidden w-full">
            <ContentMixCard
              contentStrategy={contentMixData}
              description={contentMixDescription}
            />
          </div>
          <div
            className="flex flex-col space-y-2 overflow-y-auto max-h-[calc(100vh-4rem) pb-8 mt-8"> {/* max-h-[calc(100vh-1rem)] */}
            <div className="sticky  bg-white dark:bg-gray-900"> {/* top-0 z-10 */}
              <h2
                className="text-xl font-semibold text-gray-800 dark:text-gray-100   ">
                {t('company_content.ideas')}
              </h2>
              <h5 className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                {t('company_content.ideas_description')}
              </h5>
            </div>

            {/* ####### MODAL ####### */}
            {/*rounded-full shadow-md hover:shadow-lg hover:shadow-purple-300 shadow-purple-300*/}
            <div className="relative w-full">
              <ModalBasic id="basic-modal" modalOpen={basicModalOpen} setModalOpen={setBasicModalOpen}
                          size="expand" title={modalData?.emoji + " " + modalData?.idea}
                // className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-auto w-[90%] h-[90%] max-h-md px-0"
              >
                {/* Modal content */}
                <div className="px-5 pt-4 pb-1 w-full">
                  <div className="text-sm">
                    <div className="flex items-center space-x-2">
                      {/*<span className="text-xl">{modalData?.emoji}</span>*/}
                      <div className="font-medium text-gray-800 dark:text-gray-100 mb-2">
                        {/*{modalData.idea}*/}
                      </div>
                    </div>

                    <div className="space-y-2 ">
                      {enablePostGenerator && generatePostIsLoading && (
                        <LoadingAnimation/>
                      )}


                      {generatePostIsSuccess && generatePostData ? (
                        <div className="flex flex-col space-y-2 w-full max-w-full">

                          {/* ####### RENDER CONTENT ####### */}
                          <div
                            // className="prose prose-indigo dark:text-gray-300 rounded-b-md bg-gray-900 p-2 w-full max-w-full">
                            className="prose text-base font-medium prose-indigo dark:prose-invert rounded-b-md bg-gray-100 dark:bg-gray-900 p-2 w-full max-w-full">
                            <MarkdownRenderer
                              content={cleanData(generatePostData)}/>
                          </div>

                          <div className="share-container w-full">
                            <h3 className="mb-2">{t("company_content.share_content")}:</h3>
                            <div className="share-buttons flex space-x-2 mb-4 w-full">
                              <FacebookShareButton url={auth.defaultCompany.website_url}
                                                   quote={cleanData(generatePostData)}>
                                <FacebookIcon size={32} round/>
                              </FacebookShareButton>

                              <TwitterShareButton url={auth.defaultCompany.website_url}
                                                  title={cleanData(generatePostData)}>
                                <TwitterIcon size={32} round/>
                              </TwitterShareButton>

                              <WhatsappShareButton url={auth.defaultCompany.website_url}
                                                   title={cleanData(generatePostData)}>
                                <WhatsappIcon size={32} round/>
                              </WhatsappShareButton>

                              <EmailShareButton
                                url={auth.defaultCompany.website_url}
                                subject={`${modalData?.emoji} ${modalData?.idea}`}
                                body={`${modalData?.emoji} ${modalData?.idea}
                                       ${generatePostData}`}
                                isHtml={true}>
                                <EmailIcon size={32} round/>
                              </EmailShareButton>

                              <CopyToClipboard
                                text={cleanData(generatePostData)}
                                onCopy={() => {
                                  setDataCopied(true)
                                }}
                              >
                                <button className="btn-sm bg-gray-900 text-gray-100 hover:bg-gray-800">
                                  <FaRegClipboard size={24}/>
                                </button>
                              </CopyToClipboard>
                              {dataCopied ?
                                <span className="text-sm text-gray-500 dark:text-gray-400">👍</span> : null}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <p>
                            {/*{modalData?.summary}*/}
                          </p>
                        </>
                      )}


                      {!generatePostIsLoading && (
                      <button
                        className={"relative p-0.5 inline-flex items-center justify-center font-bold overflow-hidden group rounded-md disabled:opacity-40"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setGeneratePostData(null);
                          getNewPost();
                        }}
                        disabled={generatePostIsLoading}
                      >
                        <span
                          className="w-full h-full bg-gradient-to-br from-orange-500 via-pink-500 to-fuchsia-600 group-hover:from-fuchsia-600 group-hover:via-pink-500 group-hover:to-orange-500 absolute"
                        />
                        <span
                          className="relative px-6 py-3 transition-all ease-out bg-gray-900 rounded-md group-hover:bg-opacity-0 duration-400"
                        >
                          <span className="relative text-white">{t('company_content.generate_content')}</span>
                        </span>
                      </button>
                      )}

                    </div>
                  </div>
                </div>
                {/* Modal footer */}
                <div className="px-5 py-4">
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button
                      className="btn-sm border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 text-gray-800 dark:text-gray-300"
                      onClick={(e) => {
                        e.stopPropagation();
                        setBasicModalOpen(false);
                      }}
                    >
                      {t('close')}
                    </button>

                  </div>
                </div>
              </ModalBasic>
            </div>
            {/*<div className="flex flex-col space-y-2 h-[500px] sm:min-h-[100px] relative w-full">*/}
            <div className="flex flex-col min-h-screen h-full relative w-full mb-8">
              <div className="flex flex-col space-y-2 pb-6 grow">
                <AnimatePresence mode="popLayout">
                  {contentIdeas.map((idea, index) => (
                    <ContentIdea
                      key={idea.idea}
                      idea={idea.idea}
                      summary={idea.summary}
                      content_type={idea.content_type}
                      idea_emoji={idea.emoji}
                      onRemove={() => removeContentIdea(index)}
                      updateModal={updateModal}
                      index={index}
                    />
                  ))}
                </AnimatePresence>
              </div>
            </div>


          </div>
        </div>
      )}


    </div>
  )
    ;
}

export default CompanyContent;
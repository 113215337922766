import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom'; // Assuming you're using React Router
import {TOOLS_INFO} from "../lib/tools"
import SectionHeader from "./SectionHeader";
import {useTranslation} from "react-i18next";

// Get tool by slug
function getToolBySlug(slug) {
  return TOOLS_INFO.find((tool) => tool.slug === slug);
}

// Get all tools
function getAllTools() {
  return TOOLS_INFO;
}

const ToolsItems = () => {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  const {slug} = useParams(); // Use React Router's `useParams` hook to get the slug from the URL
  const [tool, setTool] = useState(null);
  const [otherTools, setOtherTools] = useState([]);

  useEffect(() => {
    const selectedTool = getToolBySlug(slug);
    const tools = getAllTools();

    if (!selectedTool) {
      // If the tool is not found, navigate to a 404 or home page
      // Here we are just returning to avoid a render with missing tool
      return;
    }

    setTool(selectedTool);
    setOtherTools(tools.filter((t) => t.slug !== slug));
  }, [slug]);

  // If the tool is not found, render a fallback
  if (!tool) return <div>Tool not found.</div>;

  return (
    <>
      <div className="min-h-screen flex flex-col items-center px-0 mb-40">
        <div className="w-full p-2 px-0 pt-2 mt-0 rounded-lg shadow-lg">

          <SectionHeader
            title={t(`tools.${tool.key}.title`)}
            subtitle={t(`tools.${tool.key}.description`)}
            strapline=""
            className="text-center mb-4"
          />

          {tool.url ? (
            <iframe
              src={`${tool.url}`}
              className="w-full min-h-[600px] h-full md:h-[1000px] border-2 border-grey rounded-lg"
            >
            </iframe>
          ) : (
            <p className="text-gray-600">No workflow available for this tool.</p>
          )}

        </div>

        {/* About This Tool Section */}
        <div className="w-full max-w-4xl p-6 bg-white rounded-lg shadow-lg mt-8">
          <h2 className="text-2xl font-bold text-gray-700 mb-4">About This Tool</h2>
          <p>{tool.copywriting}</p>
          {/*<p>{t(`tools.${tool.key}.copywriting`)}</p>*/}
        </div>

        {/* Other Tools Section */}
        <div className="w-full max-w-4xl p-6 bg-white rounded-lg shadow-lg mt-8">
          <h2 className="text-2xl font-bold text-gray-700 mb-4">Other Tools</h2>
          <ul className="w-full grid grid-cols-1 md:grid-cols-3 gap-6">
            {otherTools.map((otherTool) => (
              <li key={otherTool.slug} className="w-full">
                <Link to={`/tool/${otherTool.slug}`}>
                  <div className="card p-6 bg-white rounded-lg shadow-md">
                    <div className="flex flex-col items-start gap-2">
                      <span className="text-3xl">{otherTool.icon}</span>
                      <h3 className="text-xl font-semibold capitalize text-gray-800">
                        {otherTool.title}
                      </h3>
                      <h4 className="text-m font-medium text-gray-500">{otherTool.category}</h4>
                      <p className="line-clamp-2 text-sm text-gray-800">
                        {otherTool.description || ''}
                      </p>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {/* Waves */}
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
    </>
  );
};

export default ToolsItems;

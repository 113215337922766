import React from 'react';
import PieChart from "../../charts/PieChart";
import {tailwindConfig} from '../../utils/Utils';
import {useTranslation} from "react-i18next";

import log from "../../util/logger"

function ContentMixCard(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  // const recommendations = props.contentStrategy.recommendations;
  log.debug("Content Mix Card props: ", props);

  const recommendations = props.contentStrategy.data.content_mix.content_types || [];

  // const recommendations = [
  //   {
  //     "type": "How-to Guides",
  //     "percentage": "30%",
  //     "description": "Step-by-step tutorials on utilizing AI-driven marketing tools",
  //     "details": "1,000-1,500 words"
  //   },
  //   {
  //     "type": "Quick Tips",
  //     "percentage": "25%",
  //     "description": "Concise, actionable advice for immediate marketing improvements",
  //     "details": "300-500 words"
  //   },
  // ]


  const chartData = {
    // Just map the types to labels
    labels: recommendations.map(item => item.type),
    datasets: [
      {
        label: 'Content Mix Distribution',
        // Remove the % and convert to number
        data: recommendations.map(item => parseInt(item.percentage)),
        backgroundColor: [
          tailwindConfig().theme.colors.blue[500],      // Blue
          tailwindConfig().theme.colors.red[500],       // Red
          tailwindConfig().theme.colors.green[500],     // Green
          tailwindConfig().theme.colors.purple[500],    // Purple
          tailwindConfig().theme.colors.orange[500],    // Orange
          tailwindConfig().theme.colors.teal[500],      // Teal
          tailwindConfig().theme.colors.pink[500],      // Pink
          tailwindConfig().theme.colors.yellow[500],    // Yellow
          tailwindConfig().theme.colors.indigo[500],    // Indigo
          tailwindConfig().theme.colors.emerald[500],   // Emerald
          tailwindConfig().theme.colors.fuchsia[500],   // Fuchsia
          tailwindConfig().theme.colors.cyan[500],      // Cyan
        ],
        hoverBackgroundColor: [
          tailwindConfig().theme.colors.blue[600],      // Darker Blue
          tailwindConfig().theme.colors.red[600],       // Darker Red
          tailwindConfig().theme.colors.green[600],     // Darker Green
          tailwindConfig().theme.colors.purple[600],    // Darker Purple
          tailwindConfig().theme.colors.orange[600],    // Darker Orange
          tailwindConfig().theme.colors.teal[600],      // Darker Teal
          tailwindConfig().theme.colors.pink[600],      // Darker Pink
          tailwindConfig().theme.colors.yellow[600],    // Darker Yellow
          tailwindConfig().theme.colors.indigo[600],    // Darker Indigo
          tailwindConfig().theme.colors.emerald[600],   // Darker Emerald
          tailwindConfig().theme.colors.fuchsia[600],   // Darker Fuchsia
          tailwindConfig().theme.colors.cyan[600],      // Darker Cyan
        ],
        borderWidth: 0,
      },
    ],
  };

  // ####### ERROR HANDLING #######
  if (recommendations.length === 0) {
    // IF ERROR
    log.error('No data found for content mix card');
    return (
      <div className="h-full w-full">
        <div className="h-full bg-white dark:bg-gray-800 rounded-xl shadow-sm flex flex-col">
          <div className="flex-1 flex items-center justify-center text-gray-400 dark:text-gray-300 h-24 my-3">
            {t('contentMixCard.noData')}
          </div>
        </div>
      </div>
    )
  }

  // ####### RENDER #######
  return (
    <div className="h-full w-full">
      <div className="h-full bg-white dark:bg-gray-800 rounded-xl shadow-sm flex flex-col">
        <div className="p-2 border-b border-gray-100 dark:border-gray-700">
          <h2 className="text-base font-semibold text-gray-800 dark:text-gray-100">
            {t('contentMixCard.title')}
          </h2>
          <p className="mt-0.5 text-xs italic text-gray-600 dark:text-gray-400">
            {/*{t('contentMixCard.description')}*/}
            {props.description}
          </p>
        </div>

        <div className="flex-1 grid grid-cols-2 gap-4 p-4 min-h-0">
          <div className="h-full">
            <PieChart
              data={chartData}
              width="150px"
              height="150px"
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }}
            />
          </div>

          <div className="overflow-y-auto">
            {recommendations.map((item, index) => (
              <div
                key={index}
                className="flex items-start p-1 rounded hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors mb-1 last:mb-0"
              >
                <div
                  className="w-1.5 h-1.5 mt-0.5 rounded-full flex-shrink-0"
                  style={{backgroundColor: chartData.datasets[0].backgroundColor[index]}}
                />
                <div className="ml-1 flex-1 min-w-0">
                  <div className="flex items-center gap-0.5">
                    <span className="font-medium text-xs text-gray-800 dark:text-gray-100 truncate">
                      {item.type}
                    </span>
                    <span className="text-gray-500 dark:text-gray-400 text-xs">
                      ({item.percentage})
                    </span>
                  </div>
                  <p className="text-xs text-gray-600 dark:text-gray-400 leading-tight truncate">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentMixCard;

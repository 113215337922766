/**
 * Sunokrom API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CompanyCreate model module.
 * @module model/CompanyCreate
 * @version 0.1.0
 */
class CompanyCreate {
  /**
   * Constructs a new <code>CompanyCreate</code>.
   * @alias module:model/CompanyCreate
   * @param name {String}
   * @param registrationNumber {String}
   */
  constructor(name, registrationNumber) {

    CompanyCreate.initialize(this, name, registrationNumber);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, name, registrationNumber) {
    obj['name'] = name;
    obj['registration_number'] = registrationNumber;
  }

  /**
   * Constructs a <code>CompanyCreate</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CompanyCreate} obj Optional instance to populate.
   * @return {module:model/CompanyCreate} The populated <code>CompanyCreate</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CompanyCreate();

      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('registration_number')) {
        obj['registration_number'] = ApiClient.convertToType(data['registration_number'], 'String');
      }
      if (data.hasOwnProperty('industry')) {
        obj['industry'] = ApiClient.convertToType(data['industry'], 'String');
      }
      if (data.hasOwnProperty('website_url')) {
        obj['website_url'] = ApiClient.convertToType(data['website_url'], 'String');
      }
      if (data.hasOwnProperty('logo_url')) {
        obj['logo_url'] = ApiClient.convertToType(data['logo_url'], 'String');
      }
      if (data.hasOwnProperty('contact_email')) {
        obj['contact_email'] = ApiClient.convertToType(data['contact_email'], 'String');
      }
      if (data.hasOwnProperty('contact_phone')) {
        obj['contact_phone'] = ApiClient.convertToType(data['contact_phone'], 'String');
      }
      if (data.hasOwnProperty('address')) {
        obj['address'] = ApiClient.convertToType(data['address'], 'String');
      }
      if (data.hasOwnProperty('city')) {
        obj['city'] = ApiClient.convertToType(data['city'], 'String');
      }
      if (data.hasOwnProperty('state')) {
        obj['state'] = ApiClient.convertToType(data['state'], 'String');
      }
      if (data.hasOwnProperty('postal_code')) {
        obj['postal_code'] = ApiClient.convertToType(data['postal_code'], 'String');
      }
      if (data.hasOwnProperty('country')) {
        obj['country'] = ApiClient.convertToType(data['country'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('subscription_plan')) {
        obj['subscription_plan'] = ApiClient.convertToType(data['subscription_plan'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CompanyCreate</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CompanyCreate</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CompanyCreate.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['registration_number'] && !(typeof data['registration_number'] === 'string' || data['registration_number'] instanceof String)) {
      throw new Error("Expected the field `registration_number` to be a primitive type in the JSON string but got " + data['registration_number']);
    }
    // ensure the json data is a string
    if (data['industry'] && !(typeof data['industry'] === 'string' || data['industry'] instanceof String)) {
      throw new Error("Expected the field `industry` to be a primitive type in the JSON string but got " + data['industry']);
    }
    // ensure the json data is a string
    if (data['website_url'] && !(typeof data['website_url'] === 'string' || data['website_url'] instanceof String)) {
      throw new Error("Expected the field `website_url` to be a primitive type in the JSON string but got " + data['website_url']);
    }
    // ensure the json data is a string
    if (data['logo_url'] && !(typeof data['logo_url'] === 'string' || data['logo_url'] instanceof String)) {
      throw new Error("Expected the field `logo_url` to be a primitive type in the JSON string but got " + data['logo_url']);
    }
    // ensure the json data is a string
    if (data['contact_email'] && !(typeof data['contact_email'] === 'string' || data['contact_email'] instanceof String)) {
      throw new Error("Expected the field `contact_email` to be a primitive type in the JSON string but got " + data['contact_email']);
    }
    // ensure the json data is a string
    if (data['contact_phone'] && !(typeof data['contact_phone'] === 'string' || data['contact_phone'] instanceof String)) {
      throw new Error("Expected the field `contact_phone` to be a primitive type in the JSON string but got " + data['contact_phone']);
    }
    // ensure the json data is a string
    if (data['address'] && !(typeof data['address'] === 'string' || data['address'] instanceof String)) {
      throw new Error("Expected the field `address` to be a primitive type in the JSON string but got " + data['address']);
    }
    // ensure the json data is a string
    if (data['city'] && !(typeof data['city'] === 'string' || data['city'] instanceof String)) {
      throw new Error("Expected the field `city` to be a primitive type in the JSON string but got " + data['city']);
    }
    // ensure the json data is a string
    if (data['state'] && !(typeof data['state'] === 'string' || data['state'] instanceof String)) {
      throw new Error("Expected the field `state` to be a primitive type in the JSON string but got " + data['state']);
    }
    // ensure the json data is a string
    if (data['postal_code'] && !(typeof data['postal_code'] === 'string' || data['postal_code'] instanceof String)) {
      throw new Error("Expected the field `postal_code` to be a primitive type in the JSON string but got " + data['postal_code']);
    }
    // ensure the json data is a string
    if (data['country'] && !(typeof data['country'] === 'string' || data['country'] instanceof String)) {
      throw new Error("Expected the field `country` to be a primitive type in the JSON string but got " + data['country']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    // ensure the json data is a string
    if (data['subscription_plan'] && !(typeof data['subscription_plan'] === 'string' || data['subscription_plan'] instanceof String)) {
      throw new Error("Expected the field `subscription_plan` to be a primitive type in the JSON string but got " + data['subscription_plan']);
    }

    return true;
  }


}

CompanyCreate.RequiredProperties = ["name", "registration_number"];

/**
 * @member {String} name
 */
CompanyCreate.prototype['name'] = undefined;

/**
 * @member {String} registration_number
 */
CompanyCreate.prototype['registration_number'] = undefined;

/**
 * @member {String} industry
 */
CompanyCreate.prototype['industry'] = undefined;

/**
 * @member {String} website_url
 */
CompanyCreate.prototype['website_url'] = undefined;

/**
 * @member {String} logo_url
 */
CompanyCreate.prototype['logo_url'] = undefined;

/**
 * @member {String} contact_email
 */
CompanyCreate.prototype['contact_email'] = undefined;

/**
 * @member {String} contact_phone
 */
CompanyCreate.prototype['contact_phone'] = undefined;

/**
 * @member {String} address
 */
CompanyCreate.prototype['address'] = undefined;

/**
 * @member {String} city
 */
CompanyCreate.prototype['city'] = undefined;

/**
 * @member {String} state
 */
CompanyCreate.prototype['state'] = undefined;

/**
 * @member {String} postal_code
 */
CompanyCreate.prototype['postal_code'] = undefined;

/**
 * @member {String} country
 */
CompanyCreate.prototype['country'] = undefined;

/**
 * @member {String} description
 */
CompanyCreate.prototype['description'] = undefined;

/**
 * @member {String} subscription_plan
 */
CompanyCreate.prototype['subscription_plan'] = undefined;


export default CompanyCreate;


import React from 'react';

function CompanyTileCard(props) {
  return (
    <div className="col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-gray-800 shadow-xl rounded-xl">
      <div className="flex flex-col h-full">
        {/* Card top */}
        <div className="grow p-5">
          <div className="flex justify-between items-start">
            {/* Image + name */}
            <header>
              <div className="flex mb-2">
                <div className="relative inline-flex items-start mr-5">
                  <img
                    className="rounded-full bg-white opacity-90 w-24 h-24 object-contain"
                    src={props.image}
                    alt={props.name}
                  />
                </div>
                <div className="mt-1 pr-1">
                  <h2 className="text-2xl leading-snug justify-center font-semibold">{props.name}</h2>
                  <div className="flex text-sm items-center">
                    <span>{props.location}</span></div>
                </div>
              </div>
            </header>
            {/* Menu button */}
            {/*<EditMenu align="right" className="relative inline-flex shrink-0">*/}
            {/*  <li>*/}
            {/*    <Link*/}
            {/*      className="font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-3"*/}
            {/*      to="#0">Reload data</Link>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <Link*/}
            {/*      className="font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-3"*/}
            {/*      to="#0">Option 2</Link>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <Link className="font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3"*/}
            {/*          to="#0">Remove</Link>*/}
            {/*  </li>*/}
            {/*</EditMenu>*/}
          </div>
          {/* Bio */}
          <div className="mt-2">
            <div className="text-sm">{props.content}</div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default CompanyTileCard;

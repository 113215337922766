import React, {useState} from "react";
import {BookmarkIcon, CheckCircleIcon} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import {useAuth} from "./../util/auth";
import {useTranslation} from "react-i18next";
import FaqSection from "./FaqSection";

function PricingSection(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  const auth = useAuth();

  const [basicModalOpen, setBasicModalOpen] = useState(false);

  const plans = [
    {
      id: "starter",
      name: t("pricing.plans.starter.name"),
      price: "109",
      deal: "49",
      subtitle: t("pricing.plans.starter.subtitle"),
      perks: t("pricing.plans.starter.perks", {returnObjects: true}),
      link: "https://buy.stripe.com/00geWr4EZ0Fd3KMcMQ"
    },
    {
      id: "pro",
      name: t("pricing.plans.pro.name"),
      price: "199",
      deal: "98",
      subtitle: t("pricing.plans.pro.subtitle"),
      featured: true,
      perks: t("pricing.plans.pro.perks", {returnObjects: true}),
      link: "https://buy.stripe.com/00gg0vdbvcnV6WY6ot"
    },
    {
      id: "business",
      name: t("pricing.plans.business.name"),
      price: "399",
      deal: "249",
      subtitle: t("pricing.plans.business.subtitle"),
      perks: t("pricing.plans.business.perks", {returnObjects: true}),
      link: "https://buy.stripe.com/cN2dSngnH5Zx1CE28e"
    },
  ];


  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-10 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-0 lg:py-6">
          {plans.map((plan, index) => (
            <div
              className={
                "rounded-lg shadow-sm flex flex-col border-2" +
                (plan.featured
                  ? " bg-blue-50 lg:border-4 border-blue-300 hover:border-blue-400 relative lg:-mx-2 lg:-my-6"
                  : "") +
                (!plan.featured
                  ? " bg-gray-100 border-gray-200 hover:border-gray-300"
                  : "")
              }
              key={index}
            >
              {plan.featured && (
                <div className="absolute top-0 right-0 h-10 w-10 flex items-center justify-center">
                  <BookmarkIcon className="inline-block w-6 h-6 text-orange-400"/>
                </div>
              )}

              <div className="p-5 lg:p-6 text-center bg-white rounded-t-lg">
                <span
                  className="inline-block text-sm uppercase tracking-wider font-semibold px-3 py-1 bg-blue-200 bg-opacity-50 text-blue-600 rounded-full mb-4">
                  {plan.name}
                </span>
                <div className="mb-1">
                <span className="text-3xl lg:text-4xl font-extrabold text-gray-700">
                  <del className="text-red-200 text-2xl">${plan.price}</del> ${plan.deal}
                </span>
                  <span className="text-gray-700 font-semibold">/mon</span>
                  <p className="text-gray-600 text-sm font-medium">
                    {plan.subtitle}
                  </p>
                </div>
              </div>

              {plan.perks && (
                <div
                  className={
                    "p-5 lg:p-6 space-y-5 lg:space-y-6 text-gray-700 grow" +
                    (plan.featured ? " text-blue-900" : "")
                  }
                >
                  <ul className="space-y-4 text-sm lg:text-base">
                    {plan.perks.map((perk, index) => (
                      <li className="flex items-center space-x-2" key={index}>
                        <CheckCircleIcon className="text-emerald-500 inline-block w-5 h-5"/>
                        <span>{perk}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="px-5 pb-5 lg:px-6 lg:pb-6">
                <Button
                  component="a"
                  href={
                    auth.user
                      ? `${plan.link}`
                      : `/auth/signup?next=/purchase/${plan.id}`
                  }
                  target={auth.user ? "_blank" : "_self"}
                  size="lg"
                  variant={plan.featured ? "primary" : "dark"}
                  isBlock={true}
                >
                  {t("pricing.select_plan")}
                </Button>

              </div>
            </div>
          ))}
        </div>

        <FaqSection
          title={t("faq.title")}
          subtitle=""
          strapline=""
          size="md"
          bgColor=""
          bgImage=""
          bgImageOpacity={1}
          textColor=""
          showSupportButton={false}
          supportUrl="https://zendesk.com"
        />


      </div>
    </Section>


  );


}

export default PricingSection;

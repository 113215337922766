import React, {useEffect, useState} from 'react';
import MarkdownIt from 'markdown-it';
import markdownItMark from 'markdown-it-mark';
import LoadingAnimation from "./LoadingAnimation";

// Initialize markdown-it with GFM-like options
const md = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
}).use(markdownItMark);

// Override rendering for specific tags to add Tailwind classes
md.renderer.rules.heading_open = (tokens, idx) => {
  const level = tokens[idx].tag;
  const tailwindClass = `text-2xl text-gray-800 dark:text-gray-100 font-bold mt-4 mb-1`;
  return `<${level} class="${tailwindClass}">`;
};

md.renderer.rules.bullet_list_open = () => {
  return `<ul class="list-disc list-inside text-gray-800 dark:text-gray-100 mb-4">`;
};

md.renderer.rules.list_item_open = () => {
  return `<li class="mb-2">`;
};

// Override table rendering for a more styled look
md.renderer.rules.table_open = () => {
  return `<table class="w-full text-gray-800 dark:text-gray-100 border-collapse border border-gray-300 mb-6 shadow-md rounded-lg overflow-hidden mt-3">`;
};

md.renderer.rules.th_open = () => {
  return `<th class="px-4 py-3 text-left font-semibold bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 border-b border-gray-300">`;
};

md.renderer.rules.td_open = (tokens, idx, options, env, self) => {
  // Check if it's an odd row to apply a different style
  const isOddRow = (tokens[idx - 2]?.info || 0) % 2 !== 0;
  const rowClass = isOddRow ? "bg-gray-50 dark:bg-gray-800" : "bg-white dark:bg-gray-900";
  return `<td class="px-4 py-3 ${rowClass} border-b border-gray-300 dark:border-gray-600">`;
};

// Override paragraph rendering for a more styled look, excluding paragraphs inside lists
md.renderer.rules.paragraph_open = (tokens, idx, options, env, self) => {
  // Check if the paragraph is inside a list item to avoid styling issues
  const isInListItem = tokens[idx - 1] && tokens[idx - 1].type === 'list_item_open';
  if (isInListItem) {
    return ''; // No <p> tag for paragraphs inside list items
  }
  return `<p class="text text-gray-800 dark:text-gray-200 leading-relaxed mb-4">`;
};

md.renderer.rules.paragraph_close = (tokens, idx, options, env, self) => {
  // Do not close paragraph tag if inside a list item
  const isInListItem = tokens[idx - 1] && tokens[idx - 1].type === 'list_item_open';
  if (isInListItem) {
    return '';
  }
  return `</p>`;
};

const MarkdownRenderer = ({content}) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    // Render markdown when content updates
    if (content) {
      setHtmlContent(md.render(content));
    }
  }, [content]);

  if (!htmlContent) {
    return <LoadingAnimation/>; // Loading indicator while content is being processed
  }

  return (
    <div
      className="markdown-body"
      dangerouslySetInnerHTML={{__html: htmlContent}}
    />
  );
};

export default MarkdownRenderer;

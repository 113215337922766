import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {BoltIcon, BuildingOfficeIcon, CogIcon, CpuChipIcon, DocumentTextIcon} from "@heroicons/react/24/solid";
import {GlobeAltIcon} from "@heroicons/react/24/outline";

function CampaignsCard(props) {

  const history = useHistory();
  const typeColor = (type) => {
    switch (type) {
      case 'Start here':
        return 'bg-green-500/20 text-green-700';
      case 'Limited-time free':
        return 'bg-green-500/20 text-green-700';
      case 'One-Time':
        return 'bg-green-500/20 text-green-700';
      case 'At Risk':
        return 'bg-yellow-500/20 text-yellow-700';
      case 'Off-Track':
        return 'bg-red-500/20 text-red-700';
      default:
        return 'bg-green-500/20 text-green-700';
      // return 'bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400';
    }
  };

  const categoryIcon = (category) => {
    switch (category) {
      case 'globe':
        return (
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-red-500">
            <GlobeAltIcon className="w-9 h-9 fill-current text-white"/>
          </div>
        );
      case 'office':
        return (
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-blue-500">
            <BuildingOfficeIcon className="w-9 h-9 fill-current text-white"/>
          </div>
        );
      case 'settings':
        return (
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-blue-500">
            <CogIcon className="w-9 h-9 fill-current text-white"/>
          </div>
        );
      case 'chip':
        return (
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-green-500">
            <CpuChipIcon className="w-9 h-9 fill-current text-white"/>
          </div>
        );
      case 'document':
        return (
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-blue-500">
            <DocumentTextIcon className="w-9 h-9 fill-current text-white"/>
          </div>
        );
      case '1':
        return (
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-red-500">
            <BoltIcon className="w-9 h-9 fill-current text-white"/>
          </div>
        );
      case '2':
        return (
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-green-500">
            <svg className="w-9 h-9 fill-current text-white" viewBox="0 0 36 36">
              <path d="M15 13v-3l-5 4 5 4v-3h8a1 1 0 000-2h-8zM21 21h-8a1 1 0 000 2h8v3l5-4-5-4v3z"/>
            </svg>
          </div>
        );
      case '3':
        return (
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-sky-500">
            <svg className="w-9 h-9 fill-current text-white" viewBox="0 0 36 36">
              <path
                d="M23 11v2.085c-2.841.401-4.41 2.462-5.8 4.315-1.449 1.932-2.7 3.6-5.2 3.6h-1v2h1c3.5 0 5.253-2.338 6.8-4.4 1.449-1.932 2.7-3.6 5.2-3.6h3l-4-4zM15.406 16.455c.066-.087.125-.162.194-.254.314-.419.656-.872 1.033-1.33C15.475 13.802 14.038 13 12 13h-1v2h1c1.471 0 2.505.586 3.406 1.455zM24 21c-1.471 0-2.505-.586-3.406-1.455-.066.087-.125.162-.194.254-.316.422-.656.873-1.028 1.328.959.878 2.108 1.573 3.628 1.788V25l4-4h-3z"/>
            </svg>
          </div>
        );
      case '4':
        return (
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-violet-500">
            <svg className="w-9 h-9 fill-current text-white" viewBox="0 0 36 36">
              <path
                d="M18 10c-4.4 0-8 3.1-8 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L18.9 22H18c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z"/>
            </svg>
          </div>
        );
      default:
        return (<div></div>);
    }
  };

  return (
    <div
      className="col-span-full sm:col-span-6 bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-md hover:shadow-lg hover:shadow-purple-300 shadow-purple-300 mx-1 sm:mx-0">
      <div className="flex flex-col h-full p-5">
        <header>
          <div className="flex items-center justify-between">
            {categoryIcon(props.category)}
            {props.members ? (
                <>
                  <div className="flex shrink-0 -space-x-3 -ml-px">
                    {
                      props.members.map(member => {
                        return (
                          <Link key={member.name} className="block" to={member.link}>
                            <img className="rounded-full border-2 border-white dark:border-gray-800 box-content"
                                 src={member.image} width="28" height="28" alt={member.name}/>
                          </Link>
                        )
                      })
                    }
                  </div>
                </>
              )
              :
              (
                null
              )}

          </div>
        </header>
        <div className="grow mt-2">
          <Link className="inline-flex text-gray-800 dark:text-gray-100 hover:text-gray-900 dark:hover:text-white mb-1"
                to={props.link}>
            <h2 className="text-xl leading-snug font-semibold">{props.title}</h2>
          </Link>
          <div className="text-sm">{props.content}</div>
        </div>
        <footer className="mt-5">
          {props.dates.from ? (
            <div className="text-sm font-medium text-gray-500 mb-2">{props.dates.from}
              <span className="text-gray-400 dark:text-gray-600">-&gt;
            </span> {props.dates.to}
            </div>
          ) : (null)}
          <div className="flex justify-between items-center">
            {props.type ? (
              <div>
                <div
                  className={`text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1 ${typeColor(props.type)}`}>{props.type}</div>
              </div>
            ) : (
              <div></div>
            )}
            <div>
              <button
                className="btn bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 text-violet-500"
                onClick={() =>
                  // window.location.href = props.link
                  history.push(props.link)
                }
              >
                {props.buttonText || 'Open'}
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default CampaignsCard;

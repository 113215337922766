import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

function AccordionBasic(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  const [open, setOpen] = useState(false);

  return (
    <div className="w-full px-1 sm:px-5 py-4 rounded-lg border border-gray-200 dark:border-gray-700/60">
      <button
        className="flex items-center justify-between w-full group mb-1"
        aria-expanded={open}
        onClick={() => setOpen(!open)}
      >
        <div className="flex items-start">
          <div className="text-sm text-gray-800 dark:text-gray-100 font-medium ml-4 sm:ml-2">
            {props.title}
          </div>
          <div className="relative group">
            <span className="ml-2 text-sm cursor-pointer">✨</span>
            {/*<div*/}
            {/*  className="absolute left-1/2 transform -translate-x-1/2 bottom-full group-hover:block mb-1 hidden w-max p-2 text-xs text-white bg-gray-800 rounded-md shadow-lg dark:bg-gray-700">*/}
            {/*  {t("dashboard.tip_information_from_ai")}*/}
            {/*</div>*/}
          </div>
        </div>


        <svg
          className={`w-8 h-8 shrink-0 fill-current text-gray-400 dark:text-gray-500 group-hover:text-gray-500 dark:group-hover:text-gray-400 ml-3 ${open && 'rotate-180'}`}
          viewBox="0 0 32 32">
          <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z"/>
        </svg>
      </button>
      <div className={`text-sm ${!open && 'hidden'}`}>
        {props.children}
      </div>
    </div>
  );
}

export default AccordionBasic;

import React, {createContext, useContext, useEffect, useState,} from "react";
import queryString from "query-string";
// import fakeAuth from "fake-auth";
import {history} from "./router";
import PageLoader from "./../components/PageLoader";

import analytics from "./analytics";
// import ApiClient from "../api/ApiClient";
import api from "../api";

import log from './logger'
import {convertStringToJson} from "./util";
import {useQueryClient} from "@tanstack/react-query";
import {GenerateRequestModel} from "./hooks";

// Whether to merge extra user data from database into `auth.user`
// const MERGE_DB_USER = true;

// Whether to connect analytics session to `user.uid`
const ANALYTICS_IDENTIFY = true;

// const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";
// const api = new ApiClient(API_URL)

// Create a `useAuth` hook and `AuthProvider` that enables
// any component to subscribe to auth and re-render when it changes.
const authContext = createContext();
export const useAuth = () => useContext(authContext);

// This should wrap the app in `src/pages/_app.js`
export function AuthProvider({children}) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook that creates the `auth` object and handles state
// This is called from `AuthProvider` above (extracted out for readability)
function useAuthProvider() {
  // Store auth user in state
  // `user` will be object, `null` (loading) or `false` (logged out)
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [localGeneratedKey, setLocalGeneratedKey] = useState(null);

  const updateLocalGeneratedKey = (key) => {
    setLocalGeneratedKey(key);
  }

  // Merge extra user data from the database
  // This means extra user data (such as payment plan) is available as part
  // of `auth.user` and doesn't need to be fetched separately. Convenient!
  // let finalUser = useMergeExtraData(user, { enabled: MERGE_DB_USER });
  let finalUser = user;

  // Add custom fields and formatting to the `user` object
  // finalUser = useFormatUser(finalUser);

  // Connect analytics session to user - TEMPORARY DISABLED
  useIdentifyUser(finalUser, {enabled: ANALYTICS_IDENTIFY});

  // Handle response from auth functions (`signup`, `signin`, and `signinWithProvider`)
  const handleAuth = async (user) => {
    log.debug('[handleAuth] RECEIVED:', user)

    let response = '';

    // if user has access_token property, then user add it in api.access_token
    if (user.access_token) {
      api.setAccessToken(user.access_token);
      let currentUser = await api.getCurrentUser();
      response = {'isnew': false, ...currentUser, ...user};

      api.setUser(response);
      setUser(response);
      log.debug('[handleAuth] currentUser:', currentUser + ' access_token on class' + api.access_token)
    }

    // Update user in state
    // setUser(user);
    return response;
  };

  // Get all companies for the user and set in setCompanies
  const updateCompanies = async (company_id = false) => {
    log.debug('[updateCompanies] getting companies');
    let companies = await api.getCompanyList();
    // I need to make a loop in the companies to get the company_id, make a query for getSocialNetworksByCompany with company_id, get the result and set it in the company inside socials key
    companies.map(async (company) => {
      let socials = await api.getSocialNetworksByCompany(company.id);
      log.debug('[updateCompanies] socials:', socials)
      company.socials = socials;
    });
    setCompanies(companies);
    log.debug('[updateCompanies] companies:', companies)
    // This is used for SocialNetowrks.jsx
    localStorage.setItem('isFirstLoad', JSON.stringify(true));
    if (company_id) {
      const company = companies.find(company => company.id === company_id);
      log.debug('[updateCompanies] company_id => set Default Company:', company)
      setCompany(company);
    } else if (!company) {
      const defaultCompany = companies[companies.length - 1];
      log.debug('[updateCompanies] Default company set:', defaultCompany)
      setCompany(companies[companies.length - 1]);
    }
  };

  const updateCompany = async (NewCompany) => {
    // if not a json object throw an error
    if (typeof NewCompany !== 'object') {
      throw new Error('NewCompany must be an object');
    }
    log.debug('[updateNewCompany] NewCompany:', NewCompany)
    setCompany(NewCompany);

  }

  const CheckStatus = async () => {
    return await api.checkAPIStatus();
    // // loop for checking status of api 3 times
    // for (let i = 0; i < 3; i++) {
    //   const result = await api.checkAPIStatus();
    //   log.debug('[CheckStatus] :', result)
    //   if (result && i > 2) {
    //     return result;
    //   }
    // }
  }

  const signup = (email, password) => {
    return api
      .signup(email, password)
      .then((response) => {
        if (response.access_token) {
          response.isnew = true;
        }
        log.debug('[signup] response:', response)
        handleAuth(response)
      });
  };

  const signin = (email, password) => {
    return api
      .signin(email, password)
      .then((response) => handleAuth(response));
  };

  const signinWithProvider = (name) => {
    return api
      .signinWithProvider(name)
      .then((response) => handleAuth(response));
  };

  const signout = () => {
    log.debug('[signout] signing out user:', user)
    api.signout();

    log.debug("Cleaning STATES");
    setUser(false);
    setCompany(null);
    setCompanies(null);

    log.debug("Cleaning LOCALSTORAGE");
    localStorage.removeItem('user');
    localStorage.removeItem('company');
    localStorage.removeItem('isFirstLoad');
    // REMEMBER TO ACTIVATE LATER AFTER SOLVE THE ISSUE WITH AI API
    // localStorage.clear();
    // sessionStorage.clear();

    // queryClient.clear();
    // # Clean all states
  };

  const sendPasswordResetEmail = (email) => {
    log.debug('[sendPasswordResetEmail] email:', email)
    return api.sendPasswordResetEmail(email);
  };

  const confirmPasswordReset = (token_to_reset_password, newPassword) => {
    // [INTEGRATING AN AUTH SERVICE]: If not passing in "code" as the second
    // arg above then make sure getFromQueryString() below has the correct
    // url parameter name (it might not be "code").

    // Get code from query string object
    // const resetCode = code || getFromQueryString("code");
    return api.confirmPasswordReset(token_to_reset_password, newPassword);
  };

  const updatePassword = (data) => {
    return api.updatePassword(user.access_token, data.currentPass, data.pass);
  };

  // Update auth user and persist data to database
  // Call this function instead of multiple auth/db update functions
  const updateProfile = async (data) => {
    log.debug('[updateProfile] received:', data)
    const {email, name, picture} = data;

    // Update built-in auth profile fields
    // These fields are renamed in `useFormatUser`, so when updating we
    // need to make sure to use their original names (`name`, `picture`, etc)
    if (name || email || picture) {
      let fields = {};
      if (name) fields.name = name;
      if (email) fields.email = email;
      if (picture) fields.picture = picture;

      // Compare fields with auth.user and create a structure with differences
      let differences = {};
      for (let key in fields) {
        if (fields[key] !== user[key]) {
          differences[key] = fields[key];
        }
      }

      if (Object.keys(differences).length > 0) {
        await api.updateProfile(user.uid, differences);
      }
    }

    // Persist all data to the database
    // await updateUser(user.uid, data);

    // Update user in state
    api.getCurrentUser().then((response) => {
      handleAuth(response)
    });

  };


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     log.debug('[handleAuth] user:', user);
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, [user]);

  useEffect(() => {
    log.debug(CheckStatus());
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      log.debug('[useEffect] Found user in localStorage:', storedUser);
      setUser(JSON.parse(storedUser));
      api.setUser(JSON.parse(storedUser));
      if (window.location.pathname === '/auth/signin' || window.location.pathname === '/auth/signup') {
        log.warn('[useEffect] User found in localStorage, redirecting to dashboard.');
        history.push("/dashboard");
      }
    } else {
      // if page is pricing do nothing
      if (window.location.pathname === '/pricing' || window.location.pathname === '/auth/signin' || window.location.pathname === '/auth/signup') {
        return;
      }
      log.debug('[useEffect] No user found in localStorage, redirecting to sign-in page.');
      history.replace("/auth/signin");
    }
    const unsubscribe = api.onChange(async ({user}) => {
      log.debug('[useEffect] subscribing to user on mount')
      if (user) {
        log.debug('[useEffect] setting user:', user)
        setUser(user);
      } else {
        log.debug('[useEffect] setting user:', user)
        setUser(false);
      }

    });
  }, []);
  // ================== OPEN AI CALL ---START ==================
  // const {
  //   data: dataGenerated, // Destructure and rename data to dataGenerated
  //   isLoading: isGenerating,
  //   error: generateError,
  //   isError: isGenerateError,
  //   isSuccess: isGenerated,
  //   refetch,
  // } = useQuery(
  //   ['generatedData', website_to_check],
  //   () => {
  //     const prompt = PROMPT_COMPANY_OVERVIEW + 'Business name: ' + JSON.stringify(formData?.companyName) + 'Company Headline: '
  //       + JSON.stringify(formData?.companyHeadline) + JSON.stringify(varResponse)
  //     return api.fetchGeneratedData(prompt)
  //   },
  //   {
  //     staleTime: Infinity, // Data never goes stale
  //     cacheTime: Infinity, // Data stays in cache forever
  //     refetchOnWindowFocus: false, // Disable refetch on window focus
  //     enabled: shoudGenerateData && !getLocalStorageData(localGeneratedKey), // Only fetch if localStorage is empty
  //     initialData: (() => {
  //       const localData = getLocalStorageData(localGeneratedKey);
  //       const ret = localData !== null && localData !== undefined && localData !== '' ? localData : undefined;
  //       log.debug("Initial data for Generate Data:", ret);
  //       return ret
  //     })(),
  //     onSuccess: (ret) => {
  //       const data = ret.data || ret
  //       log.info("Data type:", typeof data);
  //       log.info("Data generated successfully:", data);
  //       const dataClean = convertStringToJson(data);
  //       log.debug("###### Generated data saved in the backend, saved in localStorage");
  //       log.debug("Raw data:", data);
  //       log.debug("Clean data:", dataClean);
  //       setLocalStorageData(localGeneratedKey, dataClean); // Store data in localStorage on successful fetch
  //     },
  //   }
  // );
  const queryClient = useQueryClient(), handleGenerateSuccess = (ret) => {
    const data = ret.data || ret;
    log.info("Data type:", typeof data);
    log.info("Data generated successfully:", data);
    const dataClean = convertStringToJson(data);
    // setLocalStorageData(localGeneratedKey, dataClean); // Store data in localStorage on successful fetch
  }, generateCompanyOverview = async (websiteCheckResponse, url) => {
    // if (getLocalStorageData(localGeneratedKey)) {
    //   log.warn('Company Overview already generated, skipping API call');
    //   return
    // }
    const prompt = 'Business name: ' + company?.companyName + ', Company Headline: ' + company?.companyHeadline + websiteCheckResponse;
    let requestModel = new GenerateRequestModel(prompt, 2000, 'openai', url, "useAIInitialReportData");
    try {
      // Fetch query manually without depending on component lifecycle
      const data = await queryClient.fetchQuery(
        ['generatedData', company.website],
        () => api.fetchGeneratedData2(requestModel),
        {
          staleTime: Infinity,
          cacheTime: Infinity,
          refetchOnWindowFocus: false,
        }
      );
      handleGenerateSuccess(data); // Call handleSuccess after successfully fetching data
    } catch (error) {
      console.error('Error fetching generated data:', error);
    }
  };
  // ================== OPEN AI CALL ---END ==================


  return {
    user: finalUser,
    signup,
    signin,
    signinWithProvider,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updatePassword,
    updateProfile,
    company,
    companies,
    updateCompanies,
    updateCompany,
    defaultCompany: company,
    updateLocalGeneratedKey,
    generateCompanyOverview
  };
}


// Connect analytics session to current user
function useIdentifyUser(user, {enabled}) {
  useEffect(() => {
    if (user && user.uid && enabled) {
      log.debug('[useIdentifyUser USEEFFECT]  identifying user:', user)
      analytics.identify(user.uid);
    }
  }, [user, enabled]);
}

// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
  return function RequireAuthHOC(props) {
    // Get authenticated user
    const auth = useAuth();

    useEffect(() => {
      // Redirect if not signed in
      if (auth.user === false) {
        history.replace("/auth/signin");
      }
      // const timeoutId = setTimeout(() => {
      //   if (auth.user === null) {
      //     log.error('[requireAuth] Loading timeout, redirecting to sign-in page.');
      //     if (!auth.user) {
      //       log.debug('[requireAuth] auth.user not found:', auth.user);
      //       history.replace("/auth/signin");
      //     }
      //   }
      // }, 5000);
    }, [auth]);

    // Show loading indicator
    // We're either loading (user is `null`) or about to redirect from above `useEffect` (user is `false`)
    if (!auth.user) {
      log.debug('[requireAuth] auth.user not found:', auth);
      return <PageLoader/>;

    }


    // Render component now that we have user
    return <Component {...props} />;
  };
};

const getFromQueryString = (key) => {
  return queryString.parse(window.location.search)[key];
};

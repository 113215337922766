import React from "react";
import {useAuth} from "../../util/auth";
import {useTranslation} from "react-i18next";
import {FaChartPie, FaUsers} from "react-icons/fa";
import EmptyState from "../../pages/utility/EmptyState";

const variants = {
  initial: {opacity: 0, scale: 0.8},
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: [0.175, 0.85, 0.42, 0.96], // Easing function for a bounce effect
    },
  },
  exit: {opacity: 0, scale: 0.8, transition: {duration: 0.3}},
};


function StrategySection(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  const auth = useAuth();

  return (
    <div>
      <EmptyState
        subtitle="In development"
        description="This section is still in development. Please check back later."
      />
    </div>
  )


  return (
    <div
      className="grow flex flex-col md:translate-x-0 transition-transform duration-300 ease-in-out translate-x-0"
    >
      <div className="relative px-0 sm:px-6 pb-4">
        <div className="flex flex-col xl:flex-row xl:space-x-16">
          {/* Main content */}
          <div className="flex-1 space-y-5 mb-8 xl:mb-0">

            {/* TARGET AUDIENCE */}
            <div className="border-b-2 border-white dark:border-gray-800 box-content p-2">
              <h2 className="text-gray-800 dark:text-gray-100 font-semibold mb-2">
                <div className="flex items-center">
                  <FaUsers className="mr-2"/>
                  {t("marketing_strategy.target_audience")}
                </div>
              </h2>
              <div className="text-sm space-y-2">
                <p>
                  {props.CompanyBio || "The goal of this section is to present a concise yet informative overview of the company"}
                </p>
              </div>
            </div>

            {/* MARKET analysis */}

            <div className="border-b-2  border-white dark:border-gray-800 box-content p-2">
              <h2 className="text-gray-800 dark:text-gray-100 font-semibold mb-2">
                <div className="flex items-center">
                  <FaChartPie className="mr-2"/>
                  {t("dashboard.market_analysis")}
                  {/* TOOLTIP */}
                  <div className="relative group">
                    <span className="ml-2 text-sm cursor-pointer">✨</span>
                    <div
                      className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-1 hidden group-hover:block w-max p-2 text-xs text-white bg-gray-800 rounded-md shadow-lg dark:bg-gray-700">
                      {t("dashboard.tip_information_from_ai")}
                    </div>
                  </div>
                </div>
              </h2>
              <div>
                <div className="text-sm space-y-2">
                  <p>
                    {props.CompanyBio || "The goal of this section is to present a concise yet informative overview of the company"}
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
  ;
}

export default StrategySection;

import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useAuth} from "../../util/auth";
import {useFormContext} from '../../util/FormContext';
import api from "../../api";
import log from '../../util/logger';
import CompanyCreate from "../../api/model/CompanyCreate";
import {useTranslation} from "react-i18next";

import {useAIInitialReportData} from "../../util/hooks";
import {getFullLanguageName, removeImageTagFromMarkdown} from "../../util/util";

const Step4Form = forwardRef((props, ref) => {
  log.debug("------- Step4Form.js - Step4Form called -------");
  const {t, i18n} = useTranslation();
  const {formData, updateFormData} = useFormContext();

  const auth = useAuth();

  // State for form errors
  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    log.debug('Step4Form: formData:', formData);
    updateCompany();
  }, []);

  log.debug('Step4Form: formData:', formData);

  // Function to Update a company using formData
  const updateCompany = () => {
    let companyUpdate = new CompanyCreate()
    companyUpdate.city = formData.city
    companyUpdate.postalCode = formData.postalCode
    companyUpdate.street = formData.street
    companyUpdate.country = formData.country
    companyUpdate.contact_email = formData.companyEmail || null
    companyUpdate.contact_phone = formData.phoneNumbers
    companyUpdate.address = formData.street
    companyUpdate.state = formData.city

    log.debug('Step4Form: update company with formData:', formData);

    api.updateCompanyInfo(formData.company_id, companyUpdate).then((response) => {
      log.debug('Step4Form: updateCompanyInfo response:', response);
      log.debug("Company Updated:", response);
      auth.updateCompanies(formData.company_id)
    })


    //   api.createNewCompany(company).then((response) => {
    //     log.debug('Step4Form: createCompany response:', response);
    //     if (response && response.created_at) {
    //       log.debug("Company successfully created with created_at:", response.created_at);
    //     } else {
    //       log.error("Company creation failed or response invalid.");
    //     }
    //   }).catch((error) => {
    //     log.error("Error creating company:", error);
    //   });
  };

  // Generic handle change function for all inputs (if any future form inputs are added)
  const handleChange = (e) => {
    const {name, value} = e.target;
    updateFormData({
      ...formData,
      [name]: value,
    });
  };

  // ############## Generate AI Initial Report data ##############
  const {data, isLoading, error} = useAIInitialReportData(
    {
      ...formData,
      websiteCheckResponse: removeImageTagFromMarkdown(formData.original_response?.data?.markdown || ''),
      location: `"${formData.city}, ${formData.postalCode}, ${formData.street}, ${formData.country}"`
    },
    getFullLanguageName(i18n.language),
    (resp) => { /* onSuccess callback */
      log.debug("useAIInitialReportData Generated data", resp);
    },
    (err) => { /* onError callback */
      log.error("useAIInitialReportData Error generating data", err);
    }
  );

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    const requiredFields = ['companyName', 'city', 'postalCode', 'street', 'country'];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = true;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  // Expose validation and submission functions to parent component
  useImperativeHandle(ref, () => ({
    validateForm,
    submitForm: () => {
      if (validateForm()) {
        log.debug("Form is valid, creating company...");
        // createCompany();
      }
    },
  }));

  return (
    <div className="sm:px-4 py-8">
      <div className="max-w-xl mx-auto">
        <div className="text-center">
          <svg className="inline-flex w-16 h-16 fill-current mb-6" viewBox="0 0 64 64">
            <circle className="text-green-500/20" cx="32" cy="32" r="32"/>
            <path
              className="text-green-700"
              d="M37.22 26.375a1 1 0 1 1 1.56 1.25l-8 10a1 1 0 0 1-1.487.082l-4-4a1 1 0 0 1 1.414-1.414l3.21 3.21 7.302-9.128Z"
            />
          </svg>
          <h1 className="text-3xl text-gray-800 dark:text-gray-100 font-bold mb-8">
            {formData.companyName ? formData.companyName + ',' : ''} {t('onboarding.thanks')} 🙌
          </h1>
        </div>
      </div>
    </div>
  );
});

export default Step4Form;

import React from 'react';
import {useHistory} from "react-router-dom";

function EmptyState(props) {

  const history = useHistory();

  return (
    <>
      {/* Inline CSS for Keyframes */}
      <style>
        {`
          @keyframes gradientShift {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }

          .animate-gradient {
            background: linear-gradient(to bottom right, #ff8a05, #ffd700, #ff5478, #ff00c6);
            background-size: 200% 200%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            animation: gradientShift 5s ease infinite;
            border-radius: 0.375rem;
          }
        `}
      </style>

      <div className="flex h-[100dvh] overflow-hidden">
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-gray-900">
          <main className="grow">
            {/* Page header */}
            {props.title && (
              <div
                className="sm:flex sm:justify-between sm:items-center px-4 sm:px-6 py-1 border-b border-gray-200 dark:border-gray-700/60">
                {/* Left: Title */}
                <div className="mb-1 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 font-bold">
                    {props.title || "Empty State"}</h1>
                </div>

                {/*/!* Right: Actions *!/*/}
                {/*<div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">*/}
                {/*  /!* Add event button *!/*/}
                {/*  <button className="btn bg-gray-900 text-gray-100 hover:bg-gray-800 dark:bg-gray-100 dark:text-gray-800 dark:hover:bg-white">Add Event</button>*/}
                {/*</div>*/}

              </div>
            )}
            <div className="px-4 sm:px-6 lg:px-8 py-2 w-full max-w-9xl mx-auto">
              <div className="max-w-2xl m-auto mt-2">
                <div className="text-center px-4">

                  {/* ####### ICON ####### */}
                  {!props.noicon && (
                    <div
                      className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-gray-200 to-gray-100 dark:from-gray-700 dark:to-gray-800 mb-4">
                      <svg className="w-5 h-6 fill-current" viewBox="0 0 20 24">
                        <path className="text-gray-500 dark:text-gray-600"
                              d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"/>
                        <path className="text-gray-300 dark:text-gray-400"
                              d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"/>
                        <path className="text-gray-400 dark:text-gray-500"
                              d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"/>
                      </svg>
                    </div>
                  )}

                  <h2 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-2">
                    {props.subtitle || "Empty State"}
                  </h2>
                  <div className="mb-6">
                    {props.description || "There are no events to display. Click the button below to add a new event."}
                  </div>
                  {props.button && (
                    <a
                      href="#0"
                      className="relative p-0.5 inline-flex items-center justify-center font-bold overflow-hidden group rounded-md"
                      onClick={() => history.push(props.buttonLink || "/")}
                    >
                      {/* Gradient Background with Animation */}
                      <span className="animate-gradient"/>

                      {/* Button Content */}
                      <span
                        className="relative px-6 py-3 transition-all ease-out bg-gray-900 rounded-md group-hover:bg-opacity-0 duration-400"
                      >
                        <span className="relative text-white">
                          {props.button || "Add New Event"}
                        </span>
                      </span>
                    </a>

                    // <button
                    //   className="btn bg-gray-900 text-gray-100 hover:bg-gray-800 dark:bg-gray-100 dark:text-gray-800 dark:hover:bg-white shadow-lg shadow-gray-500/20"
                    //   onClick={() => {
                    //     history.push(props.buttonLink || "/");
                    //   }}
                    // >
                    //   {props.button || "Add New Event"}
                    // </button>
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>

  )
    ;
}

export default EmptyState;
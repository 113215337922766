import React from "react";
import {Link} from "./../util/router";
import {useTranslation} from "react-i18next";

function AuthFooter(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  return (
    <div
      className={
        "px-3 mt-6 text-sm text-center" +
        (props.className ? ` ${props.className}` : "")
      }
    >
      {props.type === "signup" && (
        <>
          {props.showAgreement && (
            <div className="mb-3">
              {t("legalText")}{" "}
              <Link to={props.termsPath} className="text-blue-600">
                {t("terms_of_service")}
              </Link>{" "}
              {t("and")}{" "}
              <Link to={props.privacyPolicyPath} className="text-blue-600">
                {t("privacy_policy")}
              </Link>
              .
            </div>
          )}

          {props.signinText}
          <Link to={props.signinPath} className="ml-3 text-blue-600">
            {props.signinAction}
          </Link>
        </>
      )}

      {props.type === "signin" && (
        <>
          <Link to={props.signupPath} className="text-blue-600">
            {props.signupAction}
          </Link>

          {props.forgotPassAction && (
            <Link to={props.forgotPassPath} className="ml-4 text-blue-600">
              {props.forgotPassAction}
            </Link>
          )}
        </>
      )}

      {props.type === "forgotpass" && (
        <>
          {props.signinText}
          <Link to={props.signinPath} className="ml-3 text-blue-600">
            {props.signinAction}
          </Link>
        </>
      )}
    </div>
  );
}

export default AuthFooter;

import React, {useState} from 'react';
import {useAuth} from "../../util/auth";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import log from "../../util/logger";
import ModalBasic from "../ModalBasic";
import {usePostGenerator, useWebsiteReport} from "../../util/hooks";
import {getFullLanguageName, getLocalStorageData, removeImageTagFromMarkdown} from "../../util/util";

function CompanyRoadmap(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  const auth = useAuth();

  const [statusRegistration, setStatusRegistration] = useState('completed');
  const [isRunningRegistration, setIsRunningRegistration] = useState(false);

  const [statusWebsiteAnalysis, setStatusWebsiteAnalysis] = useState('pending');
  const [isRunningWebsiteAnalysis, setIsRunningWebsiteAnalysis] = useState(false);

  const [statusResearch, setStatusResearch] = useState('pending');
  const [isRunningResearch, setIsRunningResearch] = useState(false);

  const [statusContentIdeas, setStatusContentIdeas] = useState('pending');
  const [isRunningContentIdeas, setIsRunningContentIdeas] = useState(false);

  const [statusCreateContent, setStatusCreateContent] = useState('pending');
  const [isRunningCreateContent, setIsRunningCreateContent] = useState(false);

  const [statusSchedulePosts, setStatusSchedulePosts] = useState('working');
  const [isRunningSchedulePosts, setIsRunningSchedulePosts] = useState(false);

  const [statusAnalyzePerformance, setStatusAnalyzePerformance] = useState('pending');
  const [isRunningAnalyzePerformance, setIsRunningAnalyzePerformance] = useState(false);

  const [basicModalOpen, setBasicModalOpen] = useState(false);

  const history = useHistory();

  // ############## Generate AI Website report ##############
  const company_details = getLocalStorageData(`websiteData-${auth.defaultCompany.website_url}`);
  const {
    data: websiteAnalysisData,
    isLoading: websiteAnalysisIsLoading,
    error: websiteAnalysisError,
    isSuccess: websiteAnalysisIsSuccess
  } = useWebsiteReport(
    {
      ...auth.defaultCompany,
      location: `"${auth.defaultCompany.city}, ${auth.defaultCompany.postalCode}, 
      ${auth.defaultCompany.street}, ${auth.defaultCompany.country}"`,
      Website_Details: company_details?.original_response?.data?.markdown ? removeImageTagFromMarkdown(company_details?.original_response?.data?.markdown) : ''
    },
    getFullLanguageName(i18n.language),
    (resp) => { /* onSuccess callback */
      log.debug("useWebsiteReport response", resp);
    },
    (err) => { /* onError callback */
      log.error("useWebsiteReport error", err);
    }
  );


  function StatusBadge({roadmapStage}) {
    return (
      <>
        {roadmapStage === 'completed' && (
          <div
            className="text-xs inline-flex font-medium bg-green-500/20 text-green-700 rounded-full text-center px-2.5 py-1">
            Completed
          </div>
        )}
        {roadmapStage === 'working' && (
          <div className="btn-xs text-xs bg-violet-500/20 text-violet-600 px-2.5 py-1 rounded-full shadow-none">
            Working on
          </div>
        )}
        {roadmapStage === 'pending' && (
          <div
            className="text-xs inline-flex font-medium bg-yellow-500/20 text-yellow-700 rounded-full text-center px-2.5 py-1">
            Pending
          </div>
        )}
      </>
    );
  }

  function StatusListItem({status, title, description, isFinal = false, linkToGo, is_running, setIsRunning}) {
    return (
      <li className="relative py-2">
        <div className="flex items-center mb-1">
          {isFinal ? (
            status === 'completed' ? (
              <div className="absolute left-0 rounded-full bg-violet-500" aria-hidden="true">
                <svg className="fill-current text-white" width="20" height="20" viewBox="0 0 20 20">
                  <path d="M14.4 8.4L13 7l-4 4-2-2-1.4 1.4L9 13.8z"/>
                </svg>
              </div>
            ) : (
              <div className="absolute left-0 rounded-full bg-white dark:bg-gray-900" aria-hidden="true">
                <svg
                  className={`fill-current text-gray-200 dark:text-gray-700 ${is_running ? 'animate-spin' : ''}`}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeDasharray={is_running ? "4" : "0"}
                >
                  <circle cx="10" cy="10" r="9" fill="none"/>
                </svg>
              </div>
            )
          ) : status === 'completed' ? (
            <>
              <div
                className="absolute left-0 h-full w-0.5 bg-gray-200 dark:bg-gray-700 self-start ml-2.5 -translate-x-1/2 translate-y-3"
                aria-hidden="true"
              ></div>
              <div className="absolute left-0 rounded-full bg-violet-500" aria-hidden="true">
                <svg className="fill-current text-white" width="20" height="20" viewBox="0 0 20 20">
                  <path d="M14.4 8.4L13 7l-4 4-2-2-1.4 1.4L9 13.8z"/>
                </svg>
              </div>
            </>
          ) : (
            <>
              <div
                className="absolute left-0 h-full w-0.5 bg-gray-200 dark:bg-gray-700 self-start ml-2.5 -translate-x-1/2 translate-y-3"
                aria-hidden="true"
              ></div>
              <div className="absolute left-0 rounded-full bg-white dark:bg-gray-900" aria-hidden="true">
                <svg
                  className={`fill-current text-gray-200 dark:text-gray-700 ${is_running ? 'animate-spin' : ''}`}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeDasharray={is_running ? "4" : "0"}
                >
                  <circle cx="10" cy="10" r="9" fill="none"/>
                </svg>
              </div>
            </>
          )}
          <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 pl-9">{title}</h3>
          {linkToGo !== undefined && linkToGo !== '' && (
            <div>
              {is_running ? (
                <button
                  className="ml-2 btn-xs w-full bg-gray-900 text-gray-100 hover:bg-gray-800 dark:bg-gray-100 dark:text-gray-800 dark:hover:bg-white disabled:border-gray-200 dark:disabled:border-gray-700 disabled:bg-white dark:disabled:bg-gray-800 disabled:text-gray-300 dark:disabled:text-gray-600 disabled:cursor-not-allowed"
                  disabled>
                  <svg className="animate-spin fill-current shrink-0" width="16" height="16" viewBox="0 0 16 16">
                    <path
                      d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"/>
                  </svg>
                  <span className="ml-2">Loading</span>
                </button>
              ) : (
                <button
                  className="ml-2 btn-xs bg-gray-900 text-gray-100 hover:bg-gray-800 dark:bg-gray-100 dark:text-gray-800 dark:hover:bg-white"
                  onClick={(e) => {
                    // setIsRunning(true); // Set is_running to true immediately
                    e.stopPropagation();
                    setIsRunningWebsiteAnalysis(true)


                    // api.websiteAnalysis(linkToGo)
                    //   .then((response) => {
                    //     console.log(response);
                    //     if (response.status === 200) {
                    //       setIsRunning(true);
                    //       setBasicModalOpen(true);
                    //     } else {
                    //       setIsRunning(false);
                    //       log.warn("Error in website analysis");
                    //     }
                    //   })
                    //   .catch((error) => {
                    //     setIsRunning(false);
                    //     log.error("Request failed:", error);
                    //   });
                  }}
                >
                  <span className="">{t("roadmap.start")}</span>
                </button>
              )}
            </div>
          )}
        </div>
        <div className="pl-9">{description}</div>
      </li>
    );
  }

  // ############## Generate POST ##############
  // const company_details = getLocalStorageData(`websiteData-${auth.defaultCompany.website_url}`);
  const {
    data: generatePostData,
    isLoading: generatePostIsLoading,
    error: generatePostError,
    isSuccess: generatePostIsSuccess,
  } = usePostGenerator(
    {
      ...auth.defaultCompany,
      location: `"${auth.defaultCompany.city}, ${auth.defaultCompany.postalCode}, 
      ${auth.defaultCompany.street}, ${auth.defaultCompany.country}"`,
      Website_Details: company_details?.original_response?.data?.markdown ? removeImageTagFromMarkdown(company_details?.original_response?.data?.markdown) : ''
    },
    getFullLanguageName(i18n.language),
    (resp) => { /* onSuccess callback */
      log.debug("useWebsiteReport Success: ", resp.data.data);
      // const sanitizedData = JSON.stringify(resp.data.data).replace(/\\n/g, '\n');
    },
    (err) => { /* onError callback */
      log.error("useWebsiteReport error", err);
    },
    true // enabled parameter to control if the query runs
  );

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-gray-900">

        <main className="grow">

          {/* Page header */}
          <div
            className="sm:flex sm:justify-between sm:items-center px-4 sm:px-6 py-1 border-b border-gray-200 dark:border-gray-700/60">

            {/* Left: Title */}
            <div className="mb-1 sm:mb-0">
              <h1 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 font-bold">
                {t("dashboard.tab.roadmap")}
              </h1>
              <p className="text text-gray-800 dark:text-gray-100">
                {t("dashboard.tab.roadmap_description")}
              </p>
            </div>

          </div>

          <div className="px-4 sm:px-6 lg:px-8 py-2 w-full max-w-9xl mx-auto">
            <div className="max-w-3xl m-auto">

              {/* STEPS */}
              <div className="xl:-translate-x-16">

                {/* Step */}
                <article className="pt-1">
                  <div className="xl:flex">
                    {/*<div className="w-32 shrink-0">*/}
                    {/*  <h2*/}
                    {/*    className="text-xl leading-snug font-bold text-gray-800 dark:text-gray-100 xl:leading-7 mb-4 xl:mb-0">Phase*/}
                    {/*    1</h2>*/}
                    {/*</div>*/}
                    <div className="grow pb-6 border-b border-gray-200 dark:border-gray-700/60">
                      <header>

                        {/* Badge */}
                        {/*<div className="flex flex-nowrap items-center space-x-2 mb-6">*/}
                        {/*  <div>*/}
                        {/*    <StatusBadge roadmapStage="working"/>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                      </header>

                      {/* List */}
                      <ul className="-my-2">
                        {/* List item */}
                        <StatusListItem
                          status={statusRegistration}
                          is_running={isRunningRegistration}
                          setIsRunning={setIsRunningRegistration}
                          title={t("roadmap.registration")}
                          description={t("roadmap.registration_description")}
                        />
                        <StatusListItem
                          status={websiteAnalysisIsSuccess ? 'completed' : statusWebsiteAnalysis}
                          is_running={websiteAnalysisIsLoading ? true : isRunningWebsiteAnalysis}
                          setIsRunning={websiteAnalysisIsLoading}
                          title={t("roadmap.website_analysis")}
                          description={t("roadmap.website_analysis_description")}
                          isFinal={false}
                          linkToGo={websiteAnalysisIsSuccess ? '' : props.company_website}
                        />
                        <StatusListItem
                          status={websiteAnalysisIsSuccess ? 'completed' : statusResearch}
                          is_running={websiteAnalysisIsLoading ? true : isRunningResearch}
                          setIsRunning={setIsRunningResearch}
                          title={t("roadmap.research")}
                          description={t("roadmap.research_description")}
                          isFinal={false}
                        />
                        <StatusListItem
                          status={statusContentIdeas}
                          is_running={isRunningContentIdeas}
                          setIsRunning={setIsRunningContentIdeas}
                          title={t("roadmap.content_ideas")}
                          description={t("roadmap.content_ideas_description")}
                          isFinal={false}
                        />
                        <StatusListItem
                          status={statusCreateContent}
                          is_running={isRunningCreateContent}
                          setIsRunning={setIsRunningCreateContent}
                          title={t("roadmap.create_content")}
                          description={t("roadmap.create_content_description")}
                          isFinal={false}
                        />
                        <StatusListItem
                          status={statusSchedulePosts}
                          is_running={isRunningSchedulePosts}
                          setIsRunning={setIsRunningSchedulePosts}
                          title={t("roadmap.schedule_posts")}
                          description={t("roadmap.schedule_posts_description")}
                          isFinal={false}
                        />
                        <StatusListItem
                          status={statusAnalyzePerformance}
                          is_running={isRunningAnalyzePerformance}
                          setIsRunning={setIsRunningAnalyzePerformance}
                          title={t("roadmap.analyze_performance")}
                          description={t("roadmap.analyze_performance_description")}
                          isFinal={true}
                        />
                      </ul>
                    </div>
                  </div>
                </article>
              </div>
            </div>

          </div>
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-auto w-3/4 max-h-[300px]">
            <ModalBasic id="basic-modal" modalOpen={basicModalOpen}
                        setModalOpen={setBasicModalOpen}
                        title="Notification"
                        size={true}
            >
              {/* Modal content */}
              <div className="px-5 pt-4 pb-1">
                <div className="text-sm">
                  <div className="font-medium text-gray-800 dark:text-gray-100 mb-2">Website Analysis</div>
                  <div className="space-y-2">
                    <p>
                      The process of analyzing your website has started. This usually takes 3 minutes to complete.
                      Feel free to close this window and continue working on other tasks. We will notify you once the
                      analysis is complete.
                    </p>
                  </div>
                </div>
              </div>
              {/* Modal footer */}
              <div className="px-5 py-4">
                <div className="flex flex-wrap justify-end space-x-2">

                  <button
                    className="btn-sm border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 text-gray-800 dark:text-gray-300"
                    onClick={(e) => {
                      e.stopPropagation();
                      setBasicModalOpen(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </ModalBasic>
          </div>
        </main>

      </div>


    </div>
  )
    ;
}

export default CompanyRoadmap;
import React, {useEffect} from 'react';
import {motion} from 'framer-motion';
import TextMotion from "../TextMotion";

function WebsiteChecking(props) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-auto mb-8 bg-white dark:bg-gray-900 overflow-hidden">

      {/* Page header */}
      <div className="text-center mb-8">
        <h1 className="text-3xl md:text-4xl text-gray-800 dark:text-gray-100 font-bold">
          Checking
        </h1>
      </div>

      {/* Animated bars and website info */}
      <div className="flex flex-col items-center space-y-6 w-full sm:px-4 sm:px-6 lg:px-8 max-w-md pb-40">

        {/* Animated bars */}
        <div className="flex justify-center items-center space-x-2">
          {[...Array(3)].map((_, index) => (
            <motion.div
              key={index}
              animate={{scaleY: [1, 1.5, 1]}}
              transition={{repeat: Infinity, duration: 0.8, ease: "easeInOut", delay: index * 0.2}}
              style={{
                width: 10,
                height: 50,
                backgroundColor: "#4A90E2",
                borderRadius: "5px",
              }}
            />
          ))}
        </div>

        {/* Website text */}
        <div className="text-center break-words">
          <TextMotion effect="flicker" text={props.website}/>
        </div>

      </div>

    </div>
  );
}

export default WebsiteChecking;

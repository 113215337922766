import React, {useEffect, useState} from 'react';
import Confetti from 'react-confetti';
import {useWindowSize} from 'react-use';

function PartyEffect() {
  const [showConfetti, setShowConfetti] = useState(true);
  const {width, height} = useWindowSize();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false); // Stop confetti after 8 seconds
    }, 12000);

    // Cleanup the timeout if the component unmounts before the timer completes
    return () => clearTimeout(timer);
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div>
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={150}
          gravity={0.2}
        />
      )}
    </div>
  );
}

export default PartyEffect;

export const TOOLS_INFO = [
  {
    "key": "brand_slogan_generator",
    "image": "AppImage08",
    "category": "Marketing",
    "popular": "",
    "stars": "4.2",
    "stars_qtd": "(22)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/slogan-generator",
    "title": "Brand Slogan Generator",
    "description": "Create memorable slogans tailored to your brand with AI-driven insights. Perfect for rebranding, marketing campaigns, and refreshing your brand identity.",
    "slug": "brand_slogan_generator",
    "icon": "💡",
    "copywriting": "Create memorable slogans tailored to your brand with AI-driven insights. Perfect for rebranding, marketing campaigns, and refreshing your brand identity. Whether you’re looking to optimize your workflow or inject creativity into your projects, this tool will have you covered. Say goodbye to the hassle and let AI bring out the best in your tasks. It’s easy to use and guarantees results with a smile—because why should work feel like work?"
  },
  {
    "key": "product_name_generator",
    "image": "AppImage04",
    "category": "Marketing",
    "popular": "",
    "stars": "4.2",
    "stars_qtd": "(78)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/product-name-generator",
    "title": "Product Name Generator",
    "description": "🚀 AI-powered naming genius: Transform product details into catchy, market-ready names instantly. Perfect for launches, rebrands, or creative blocks. Stand out effortlessly!",
    "slug": "product_name_generator",
    "icon": "📛",
    "copywriting": "AI-powered naming genius that transforms product details into catchy, market-ready names instantly. Perfect for launches, rebrands, or breaking through creative blocks. Whether you're launching a new line or refreshing an old product, this tool makes it a breeze to stand out in the marketplace. Save time and skip the stress—let AI find the perfect name for you!"
  },
  {
    "key": "5_why_analysis",
    "image": "AppImage16",
    "category": "Business",
    "popular": "",
    "stars": "4.8",
    "stars_qtd": "(12)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/5-why-analysis",
    "title": "5-Why Analysis",
    "description": "🔍 Dive deep with AI-powered 5-Why Analysis! Uncover root causes, brainstorm solutions. Perfect for problem-solvers and project managers. Customizable to fit your unique challenges. 🧠",
    "slug": "5_why_analysis",
    "icon": "📊",
    "copywriting": "AI-powered 5-Why Analysis helps you uncover root causes and brainstorm solutions with precision. Ideal for problem-solvers and project managers looking to get to the heart of any issue. Customizable to fit your unique challenges, it makes digging deep into problems efficient and insightful. Say goodbye to surface-level thinking and let AI guide you toward meaningful, impactful solutions."
  },
  {
    "key": "contract_generator",
    "image": "AppImage14",
    "category": "Business",
    "popular": "",
    "stars": "4.2",
    "stars_qtd": "(22)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/contract-generator",
    "title": "Contract Generator",
    "description": "Effortlessly create tailored contracts with this tool, ensuring accuracy and consistency. It simplifies the process but doesn’t replace the expertise of a lawyer.",
    "slug": "contract_generator",
    "icon": "📜",
    "copywriting": "Effortlessly create customized, professional contracts in no time with this easy-to-use tool. While it won't replace the expertise of a lawyer, it ensures consistency and accuracy for everyday needs. Simplifying the contract creation process, it’s perfect for small businesses or freelancers needing clear agreements without the hassle."
  },
  // {
  //   "key": "ai_army",
  //   "image": "AppImage12",
  //   "category": "Miscellaneous",
  //   "popular": "",
  //   "stars": "4.2",
  //   "stars_qtd": "(22)",
  //   "price": "Free",
  //   "price_normal": "$59.00",
  //   "url": "https://page.mindpal.space/66b84b979970500d7118d392",
  //   "title": "AI Army",
  //   "description": "Batalhão de IA: Transforme ideias em ação com inteligência artificial! Crie memes, posts, escolha nomes de bebês, receba dicas de cozinha e livros instantaneamente!",
  //   "slug": "ai_army",
  //   "icon": "🤖",
  //   "copywriting": "Transforme ideias em ação com a força de um batalhão de inteligência artificial! De memes a posts, de nomes de bebês a dicas culinárias, esse exército de IA está pronto para entrar em ação e te ajudar instantaneamente. Seja criativo sem esforço e veja resultados em um piscar de olhos!"
  // },
  {
    "key": "high_search_intent_blog_post_idea",
    "image": "AppImage13",
    "category": "Marketing",
    "popular": "Popular",
    "stars": "4.8",
    "stars_qtd": "(67)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/blog-post-ideas-from-url",
    "title": "High Search Intent Blog Post Idea",
    "description": "🔮 AI blog whisperer: Feed it your URL, watch it decode audience desires. Instantly conjures 50+ tailored post ideas 📝 Transforming your website into a content goldmine 🚀",
    "slug": "high_search_intent_blog_post_idea",
    "icon": "🔮",
    "copywriting": "Turn your website into a content goldmine with this AI-powered tool. Just feed it a URL, and it will decode your audience's desires to generate over 50 tailored blog post ideas in no time. Whether you're aiming for high search intent or engaging topics, this tool ensures you're never short of content ideas again!"
  },
  {
    "key": "website_porters_5_forces_analysis",
    "image": "AppImage20",
    "category": "Business",
    "popular": "Popular",
    "stars": "4.8",
    "stars_qtd": "(67)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/website-analysis-5-forces",
    "title": "Website Porter's 5 Forces Analysis",
    "description": "A user-friendly web tool that demystifies Porter's 5 Forces analysis. Input a URL to uncover key business insights, ideal for strategy novices and experts alike.",
    "slug": "website_porters_5_forces_analysis",
    "icon": "📊",
    "copywriting": "This user-friendly tool breaks down Porter's 5 Forces Analysis, offering invaluable insights for both strategy beginners and experts. Just input a URL, and it will reveal the key factors influencing your business's competitive position. It's a breeze to use and offers deep insights for building smarter strategies."
  },
  {
    "key": "website_feedback",
    "image": "AppImage23",
    "category": "Marketing",
    "popular": "Popular",
    "stars": "4.8",
    "stars_qtd": "(67)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/landpage-feedback-generator",
    "title": "Website Feedback",
    "description": "Your website's feelings don't matter. Users do. We'll help you stop scaring them away.",
    "slug": "website_feedback",
    "icon": "🔍",
    "copywriting": "Your website’s feelings don’t matter—your users do! This tool helps you figure out what scares users away and what keeps them engaged. Friendly, no-nonsense feedback that makes improving your site simple and effective. Let your website attract users instead of pushing them away!"
  },
  {
    "key": "facebook_post",
    "image": "AppImage19",
    "category": "Marketing",
    "popular": "",
    "stars": "4.5",
    "stars_qtd": "(30)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/fb-post-from-keyword",
    "title": "Facebook Post",
    "description": "Make Aunt Karen proud! Craft posts that’ll have everyone clicking 'Like' faster than ever.",
    "slug": "facebook_post",
    "icon": "✍️",
    "copywriting": "Craft Facebook posts so good, even Aunt Karen will be impressed! This tool helps you create engaging content that makes your audience click 'Like' in no time. Whether you're promoting a brand or just having fun, this tool turns your ideas into viral-ready content effortlessly."
  },
  {
    "key": "twitter_post",
    "image": "AppImage12",
    "category": "Marketing",
    "popular": "",
    "stars": "4.6",
    "stars_qtd": "(28)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/twitter-post-from-keyword",
    "title": "Twitter Post",
    "description": "Tweets so fire, even your ex will retweet. Stand out, 280 characters at a time.",
    "slug": "twitter_post",
    "icon": "✍️",
    "copywriting": "Create tweets so fire, even your ex might retweet! This tool helps you stand out in just 280 characters. Perfect for crafting snappy, attention-grabbing content, it’ll make sure your posts rise above the noise and get noticed!"
  },
  {
    "key": "instagram_post",
    "image": "AppImage05",
    "category": "Marketing",
    "popular": "Popular",
    "stars": "4.8",
    "stars_qtd": "(45)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/insta-post-from-keyword",
    "title": "Instagram Post",
    "description": "AI so good, it turns your Insta game from zero to influencer real quick.",
    "slug": "instagram_post",
    "icon": "✍️",
    "copywriting": "Ready to go from zero to influencer? This AI-powered tool creates Instagram posts that’ll have you racking up likes and followers in no time. Say goodbye to writer’s block and hello to an Insta feed that shines with every post. Let the AI do the hard work while you enjoy the engagement!"
  },
  {
    "key": "blog_post",
    "image": "AppImage21",
    "category": "Marketing",
    "popular": "Editor's Pick",
    "stars": "4.7",
    "stars_qtd": "(52)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/blog-post-from-keyword",
    "title": "Blog Post",
    "description": "Type less, blog more. Let AI handle SEO so you can enjoy the applause.",
    "slug": "blog_post",
    "icon": "✍️",
    "copywriting": "Type less, blog more! This AI tool helps you create high-quality, SEO-optimized blog posts in no time. Whether you're a seasoned writer or just starting, it takes the guesswork out of content creation and lets you focus on the fun part—connecting with your audience!"
  },
  {
    "key": "customer_birthday_message",
    "image": "AppImage03",
    "category": "Miscellaneous",
    "popular": "",
    "stars": "4.9",
    "stars_qtd": "(60)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/birthday-message",
    "title": "Customer Birthday Message",
    "description": "Never forget a customer’s birthday again! AI-crafted messages that even Grandma would approve.",
    "slug": "customer_birthday_message",
    "icon": "🎉",
    "copywriting": "Never miss a customer's birthday again with this AI-crafted messaging tool. It generates heartfelt messages that even Grandma would approve of! Perfect for businesses that want to keep their customers feeling appreciated and remembered without lifting a finger."
  },
  {
    "key": "linkedin_post",
    "image": "AppImage29",
    "category": "Marketing",
    "popular": "",
    "stars": "4.6",
    "stars_qtd": "(35)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/linkedin-post-from-keyword",
    "title": "LinkedIn Post",
    "description": "LinkedIn content that screams, 'Hire me!' without you actually having to scream. Win-win!",
    "slug": "linkedin_post",
    "icon": "✍️",
    "copywriting": "Create LinkedIn posts that scream, 'Hire me!' without the need to actually scream. This tool helps you craft professional, engaging content that’s perfect for networking, job hunting, or establishing your authority in your field. Let your profile stand out while you let AI handle the heavy lifting!"
  },
  {
    "key": "website-assessment",
    "image": "AppImage25",  // Placeholder image
    "category": "Business",  // Category for business-related tools
    "popular": "",
    "stars": "4.7",
    "stars_qtd": "(58)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/website-assessment",
    "title": "Website Assessment",
    "description": "Uncover your website’s full potential with a comprehensive assessment. Optimize functionality and design to boost performance and elevate user experience seamlessly.",
    "slug": "website-assessment",
    "icon": "🗒",
    "copywriting": "Unleash your website's true potential with a detailed, in-depth assessment. Analyze speed, functionality, SEO performance, and design to enhance user experience and drive better results. Our expert recommendations ensure your website stays competitive and fully optimized for success. Whether you’re looking to improve user retention or increase traffic, this tool gives you the insights you need to thrive in today’s digital world."
  },
  {
    "key": "branding_audit",
    "image": "AppImage26",  // Placeholder image
    "category": "Marketing",  // Category for marketing-related tools
    "popular": "",
    "stars": "4.6",
    "stars_qtd": "(42)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/branding-audit",
    "title": "Branding Audit",
    "description": "The Branding Audit AI reviews key brand elements like user personas, UVP, logo, colors, and tagline. It offers insights and recommendations to improve brand consistency and effectiveness.",
    "slug": "branding_audit",
    "icon": "🕵️",
    "copywriting": "The Branding Audit AI workflow thoroughly evaluates key brand elements like user personas, unique value propositions (UVP), logo, colors, and tagline. It provides actionable insights and recommendations for enhancing brand consistency and effectiveness. Whether you're looking to strengthen your brand identity or align messaging with your target audience, this tool gives you data-driven suggestions to stand out in a competitive market. Perfect for refining your brand and creating lasting impact."
  },
  {
    "key": "outbound-sales-writer",
    "slug": "b2b-email-sequence",
    "image": "AppImage08",
    "category": "Sales",
    "popular": "",
    "stars": "4.2",
    "stars_qtd": "(22)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/outbound-sales-writer",
    "title": "Outbound Sales Email Writer",
    "description": "Generate high-converting outbound sales emails tailored to your audience. Ideal for sales teams seeking to improve their outreach and close deals faster.",
    "icon": "✉️",
    "copywriting": "The Outbound Sales Email Writer helps you create high-converting sales emails that speak directly to your prospects. Whether you're targeting new leads or following up with existing contacts, this tool ensures your messages are personalized and persuasive. Save time crafting outreach emails and boost your response rates with AI-driven suggestions tailored for each recipient. Designed for sales professionals who want to focus on selling, not writing. Let AI handle the copy so you can close deals more efficiently."
  },
  {
    "key": "b2b-lead-outreach",
    "slug": "b2b-email-sequence",
    "image": "AppImage31",
    "category": "Sales",
    "popular": "",
    "stars": "4.4",
    "stars_qtd": "(32)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/b2b-lead-outreach",
    "title": "B2B Lead Outreach Plan",
    "description": "Strategically plan and execute your B2B lead outreach campaigns. Generate customized outreach plans designed to convert prospects into valuable business opportunities.",
    "icon": "📈",
    "copywriting": "The B2B Lead Outreach Plan tool offers a strategic approach to reaching potential business clients. Whether you're cold-emailing or nurturing leads, this tool provides customized outreach sequences that align with your goals. Maximize your chances of converting leads into paying clients with well-timed follow-ups and personalized messaging. It’s perfect for sales teams looking to streamline their lead generation process. Don't waste time guessing what works—use data-driven insights to increase engagement and seal the deal."
  },
  {
    "key": "b2b-email-sequence",
    "slug": "b2b-email-sequence",
    "image": "AppImage25",
    "category": "Sales",
    "popular": "",
    "stars": "4.2",
    "stars_qtd": "(22)",
    "price": "Free",
    "price_normal": "$59.00",
    "url": "https://chat.sunokrom.com/b2b-email-sequence",
    "title": "B2B Cold-Outreach Email Sequence Generator",
    "description": "Create multi-step email sequences tailored for cold B2B outreach. Perfect for building relationships with potential clients and improving your sales pipeline.",
    "icon": "💼",
    "copywriting": "The B2B Cold-Outreach Email Sequence Generator is your go-to tool for crafting effective multi-step email campaigns. Ideal for building relationships with new business contacts, this tool helps you deliver targeted messages that resonate with your prospects. With AI-generated sequences that adapt to the recipient’s behavior, it optimizes engagement and nurtures leads into sales opportunities. Say goodbye to generic cold emails and hello to personalized outreach that drives results. Let this tool take the guesswork out of cold emailing."
  }

]

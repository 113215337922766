import React, {useEffect, useRef, useState} from 'react';
import Transition from '../utils/Transition';
import {useAuth} from "../util/auth";
import {useTranslation} from "react-i18next";

import {useHistory} from "react-router-dom";
import {BuildingStorefrontIcon} from "@heroicons/react/24/solid";

function CompanySelect() {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  const auth = useAuth();

  const history = useHistory();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(0);

  // Ensure defaultCompany is defined
  // const defaultCompany = auth.defaultCompany || auth.companies[0];
  // const defaultCompanyIndex = auth.companies.findIndex(company => company.id === defaultCompany.id);
  // const [selected, setSelected] = useState(defaultCompanyIndex !== -1 ? defaultCompanyIndex : 0);

  useEffect(() => {
    const defaultCompany = auth.defaultCompany || auth.companies[0];
    const defaultCompanyIndex = auth.companies.findIndex(company => company.id === defaultCompany.id);
    setSelected(defaultCompanyIndex !== -1 ? defaultCompanyIndex : 0);
  }, [auth.defaultCompany, auth.companies]);


  const trigger = useRef(null);
  const dropdown = useRef(null);

  const options = auth.companies.map((company, index) => ({
    id: index,
    company: company.name
  }));

  // close on click outside
  useEffect(() => {
    const clickHandler = ({target}) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({keyCode}) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative">
      <button
        ref={trigger}
        className="btn justify-between min-w-44 bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-100"
        aria-label="Select company"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="flex items-center">
        <BuildingStorefrontIcon className="fill-current text-gray-400 dark:text-gray-500 shrink-0 mr-2" width="16"
                                height="16"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"/>

          <span>{options[selected].company}</span>
        </span>
        <svg className="shrink-0 ml-1 fill-current text-gray-400 dark:text-gray-500" width="11" height="7"
             viewBox="0 0 11 7">
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z"/>
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className="z-10 absolute top-full right-0 w-full bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700/60 py-1.5 rounded-lg shadow-lg overflow-hidden mt-1"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="font-medium text-sm text-gray-600 dark:text-gray-300"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {
            options.map(option => {
              return (
                <button
                  key={option.id}
                  tabIndex="0"
                  className={`flex items-center w-full hover:bg-gray-50 hover:dark:bg-gray-700/20 py-1 px-3 cursor-pointer ${option.id === selected && 'text-violet-500'}`}
                  onClick={() => {
                    setSelected(option.id);
                    setDropdownOpen(false);
                    auth.updateCompany(auth.companies[option.id]);
                    window.scrollTo(0, 0);
                  }}
                >
                  <svg
                    className={`shrink-0 mr-2 fill-current text-violet-500 ${option.id !== selected && 'invisible'}`}
                    width="12" height="9" viewBox="0 0 12 9">
                    <path
                      d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z"/>
                  </svg>
                  <span>{option.company}</span>
                </button>
              )
            })
          }
          {/* "Add new company" button placed outside the map to prevent repetition */}
          <div className="border-t border-gray-200 dark:border-gray-700 mb-2"/>
          <button
            key="add-new-company"
            tabIndex="0"
            className="flex items-center w-full hover:bg-gray-50 hover:dark:bg-gray-700/20 py-1 px-3 cursor-pointer text-violet-700 text-sm"
            onClick={() => {
              setDropdownOpen(false);
              history.push("/onboarding")
            }}
          >
            <svg className="shrink-0 mr-1 fill-current text-violet-500" width="12" height="12" viewBox="0 0 12 12">
              <path d="M6 0a1 1 0 011 1v4h4a1 1 0 110 2H7v4a1 1 0 11-2 0V7H1a1 1 0 110-2h4V1a1 1 0 011-1z"/>
            </svg>
            <span>{t("dashboard.dropdown.new_company")}</span>
          </button>
        </div>
      </Transition>
    </div>
  );
}

export default CompanySelect;
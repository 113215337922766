import React from 'react';
// import Header from '../partials/Header';
import SectionHeader from "../components/SectionHeader";
import Section from "../components/Section";
import EmptyState from "./utility/EmptyState";
import {useTranslation} from "react-i18next";

function Analytics(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-12 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />

        <EmptyState
          title={false}
          subtitle={t("analytics.nodata_title")}
          description={t("analytics.nodata_description")}
        />
        />

        {/*<div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 xl">*/}

        {/*  /!* Line chart (Analytics) *!/*/}
        {/*  <AnalyticsCard01/>*/}
        {/*  /!*  Line chart (Active Users Right Now) *!/*/}
        {/*  <AnalyticsCard02/>*/}
        {/*  /!* Stacked bar chart (Acquisition Channels) *!/*/}
        {/*  <AnalyticsCard03/>*/}
        {/*  /!* Horizontal bar chart (Audience Overview) *!/*/}
        {/*  <AnalyticsCard04/>*/}

        {/*</div>*/}
      </div>
    </Section>
  );


}

export default Analytics;
import React from "react";

function LegalTerms(props) {
  return (
    <div className="prose prose-a:text-blue-600 max-w-none dark:text-dark dark-text-all">
      <h1>Terms of Service</h1>
      <p><strong>Last Updated: September, 13, 2024 </strong></p>

      <p>Welcome to Sunokrom! By accessing or using our website, services, or products (collectively, the “Services”),
        you agree to be bound by these Terms of Service (“Terms”). Please read them carefully. If you do not agree to
        these Terms, please do not use our Services.</p>

      <h2>1. Use of Services</h2>
      <p>Sunokrom grants you a non-exclusive, non-transferable, limited license to use the Services for personal or
        business purposes. You agree to use the Services in compliance with all applicable laws and regulations.</p>

      <h2>2. User Accounts</h2>
      <p>To access certain features of our Services, you may be required to create an account. You are responsible for
        maintaining the confidentiality of your account and password. You agree to provide accurate and current
        information during the registration process.</p>

      <h2>3. Prohibited Activities</h2>
      <p>You agree not to:</p>
      <ul>
        <li>Use the Services for any unlawful or fraudulent activity.</li>
        <li>Interfere with or disrupt the Services.</li>
        <li>Reverse-engineer, decompile, or attempt to extract the source code of the Services.</li>
      </ul>

      <h2>4. Intellectual Property</h2>
      <p>All content, trademarks, and materials provided through the Services are owned by Sunokrom or its licensors.
        You may not use, copy, or distribute any content without our prior written consent.</p>

      <h2>5. Termination</h2>
      <p>Sunokrom reserves the right to terminate or suspend access to the Services at any time, for any reason,
        including breach of these Terms.</p>

      <h2>6. Limitation of Liability</h2>
      <p>In no event will Sunokrom be liable for any indirect, incidental, or consequential damages arising out of your
        use of the Services. Our total liability for any claims related to the Services is limited to the amount you
        paid for the Services.</p>

      <h2>7. Changes to the Terms</h2>
      <p>We may modify these Terms at any time. Any changes will be effective immediately upon posting the updated Terms
        on our website. Your continued use of the Services indicates your acceptance of the modified Terms.</p>

      <h2>8. Governing Law</h2>
      <p>These Terms are governed by and construed in accordance with the laws of the United States and the state in
        which Sunokrom operates.</p>

      <h2>9. Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at <a
        href="mailto:support@sunokrom.com">support@sunokrom.com</a>.</p>

    </div>
  );
}

export default LegalTerms;

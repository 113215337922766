import React, {useState} from 'react';
import {useAuth} from "../../util/auth";

import {useForm} from 'react-hook-form';
import {CheckCircleIcon, ChevronLeftIcon, ChevronRightIcon, ExclamationCircleIcon} from '@heroicons/react/24/solid';
import {useTranslation} from "react-i18next";

import log from '../../util/logger';
import Tip from "../Tip";
import CompanyCreate from "../../api/model/CompanyCreate";
import api from "../../api";

const ContentStrategyForm = (props) => {
  const {t} = useTranslation();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    businessType: '',
    businessModel: '',
    industryComplexity: '',
    audience: {
      technicalLevel: '',
      contentPreference: ''
    },
    resources: {
      contentCreation: ''
    },
    goals: []
  });

  const {register, handleSubmit, formState: {errors}, trigger, watch, setValue, clearErrors} = useForm({
    mode: 'onChange',
    defaultValues: {
      businessType: '',
      businessModel: '',
      industryComplexity: '',
      audience: {
        technicalLevel: '',
        contentPreference: ''
      },
      resources: {
        contentCreation: ''
      },
      goals: []
    }
  });

  const auth = useAuth();

  const validateStep = async (nextStep) => {
    let fieldsToValidate = [];

    if (nextStep > step) {
      switch (step) {
        case 1:
          fieldsToValidate = ['businessType', 'businessModel', 'industryComplexity'];
          break;
        case 2:
          fieldsToValidate = ['audience.technicalLevel', 'audience.contentPreference'];
          break;
        case 3:
          fieldsToValidate = ['resources.contentCreation'];
          break;
        default:
          break;
      }

      const isValid = await trigger(fieldsToValidate);
      if (!isValid) {
        log.error('Validation errors:', errors);
        return false;
      }
    }

    setStep(nextStep);
    return true;
  };

  const pushContentStrategy = async (data) => {
    log.debug('pushContentStrategy Form submitted:', data);
    log.debug('pushContentStrategy type: ', typeof data);
    if (data) {
      log.debug('pushContentStrategy Updating company with content strategy:');
      let companyUpdate = new CompanyCreate()
      companyUpdate.content_mix_profile = JSON.stringify(data)
      try {
        const response = await api.updateCompanyInfo(auth.defaultCompany.id, companyUpdate);
        log.debug('pushContentStrategy: updateCompanyInfo response:', response);
        await auth.updateCompanies(auth.defaultCompany.id)
        return response;
        // auth.updateCompany(companyUpdate)
      } catch (error) {
        log.error('pushContentStrategy: updateCompanyInfo error:', error);
        return error;
        // Handle the error appropriately, e.g., show a notification to the user
      }
    }
  }

  const onSubmit = (data) => {
    log.debug('Form submitted:', data);
    const response = pushContentStrategy(data)
    if (response) {
      log.debug('Form submitted successfully:');

      if (props.updateStrategy) {
        props.updateStrategy(data);
      }

    } else {
      log.error('Form submission failed:', response);
      // Handle the error appropriately, e.g., show a notification to the user
    }

  };

  const FormSelect = ({name, label, options, tipText, error, ...props}) => (
    <div className="space-y-2">
      <div className="flex items-center flex-wrap gap-2">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {label}
        </label>
        <Tip text={tipText}/>
      </div>
      <select
        {...register(name, props.validation)}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-600 text-sm"
        value={watch(name) || ''}
        onChange={(e) => {
          setValue(name, e.target.value, {shouldValidate: true});
        }}
      >
        {options.map(({value, label}) => (
          <option key={value} value={value}>{label}</option>
        ))}
      </select>
      {error && (
        <p className="mt-1 text-sm text-red-600 flex items-center dark:text-red-400">
          <ExclamationCircleIcon className="h-4 w-4 mr-1"/>
          {error.message}
        </p>
      )}
    </div>
  );

  const renderBusinessTypeStep = () => (
    <div className="space-y-6">
      <FormSelect
        name="businessType"
        label={t('contentStrategyForm.businessType')}
        tipText={t('contentStrategyForm.businessTypeInfo')}
        error={errors.businessType}
        validation={{
          required: {
            value: true,
            message: t('contentStrategyForm.businessTypeRequired')
          }
        }}
        options={[
          {value: "", label: t('contentStrategyForm.selectBusinessTypePlaceholder')},
          {value: "b2b", label: t('contentStrategyForm.b2b')},
          {value: "b2c", label: t('contentStrategyForm.b2c')},
          {value: "both", label: t('contentStrategyForm.both')}
        ]}
      />

      <FormSelect
        name="businessModel"
        label={t('contentStrategyForm.businessModel')}
        tipText={t('contentStrategyForm.businessModelInfo')}
        error={errors.businessModel}
        validation={{
          required: {
            value: true,
            message: t('contentStrategyForm.selectBusinessModel')
          }
        }}
        options={[
          {value: "", label: t('contentStrategyForm.selectBusinessModelPlaceholder')},
          {value: "saas", label: t('contentStrategyForm.saas')},
          {value: "ecommerce", label: t('contentStrategyForm.ecommerce')},
          {value: "services", label: t('contentStrategyForm.services')},
          {value: "marketplace", label: t('contentStrategyForm.marketplace')},
          {value: "other", label: t('contentStrategyForm.other')}
        ]}
      />

      <FormSelect
        name="industryComplexity"
        label={t('contentStrategyForm.industryComplexity')}
        tipText={t('contentStrategyForm.industryComplexityInfo')}
        error={errors.industryComplexity}
        validation={{
          required: {
            value: true,
            message: t('contentStrategyForm.selectIndustryComplexity')
          }
        }}
        options={[
          {value: "", label: t('contentStrategyForm.selectComplexityLevelPlaceholder')},
          {value: "low", label: t('contentStrategyForm.low')},
          {value: "medium", label: t('contentStrategyForm.medium')},
          {value: "high", label: t('contentStrategyForm.high')}
        ]}
      />
    </div>
  );

  const renderAudienceStep = () => (
    <div className="space-y-6">
      <FormSelect
        name="audience.technicalLevel"
        label={t('contentStrategyForm.technicalSophistication')}
        tipText={t('contentStrategyForm.technicalSophisticationInfo')}
        error={errors.audience?.technicalLevel}
        validation={{
          required: {
            value: true,
            message: t('contentStrategyForm.selectTechnicalLevel')
          }
        }}
        options={[
          {value: "", label: t('contentStrategyForm.selectTechnicalLevelPlaceholder')},
          {value: "beginner", label: t('contentStrategyForm.beginner')},
          {value: "intermediate", label: t('contentStrategyForm.intermediate')},
          {value: "advanced", label: t('contentStrategyForm.advanced')},
          {value: "mixed", label: t('contentStrategyForm.mixed')}
        ]}
      />

      <FormSelect
        name="audience.contentPreference"
        label={t('contentStrategyForm.contentConsumptionPreference')}
        tipText={t('contentStrategyForm.contentConsumptionPreferenceInfo')}
        error={errors.audience?.contentPreference}
        validation={{
          required: {
            value: true,
            message: t('contentStrategyForm.selectContentPreference')
          }
        }}
        options={[
          {value: "", label: t('contentStrategyForm.selectPreferencePlaceholder')},
          {value: "quick", label: t('contentStrategyForm.quick')},
          {value: "detailed", label: t('contentStrategyForm.detailed')},
          {value: "mixed", label: t('contentStrategyForm.mixed')}
        ]}
      />
    </div>
  );

  const renderGoalsStep = () => (
    <div className="space-y-6">
      <div className="space-y-2">
        <div className="flex items-center flex-wrap gap-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            {t('contentStrategyForm.primaryBusinessGoals')}
          </label>
          <Tip text={t('contentStrategyForm.primaryBusinessGoalsInfo')}/>
        </div>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          {t('contentStrategyForm.selectAllThatApply')}
        </p>
        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-3">
          {[
            'Lead Generation',
            'Brand Awareness',
            'Customer Education',
            'Direct Sales',
            'Thought Leadership',
            'Customer Retention',
            'Community Building'
          ].map(goal => (
            <div key={goal} className="flex items-center">
              <input
                type="checkbox"
                id={goal}
                {...register('goals', {
                  validate: value => value && value.length > 0 || t('contentStrategyForm.primaryBusinessGoalsRequired')
                })}
                value={goal}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded dark:bg-gray-800 dark:border-gray-600"
                onChange={(e) => {
                  const currentGoals = watch('goals') || [];
                  const newGoals = e.target.checked
                    ? [...currentGoals, e.target.value]
                    : currentGoals.filter(g => g !== e.target.value);

                  setValue('goals', newGoals);
                  if (newGoals.length > 0) {
                    clearErrors('goals');
                  }
                }}
              />
              <label
                htmlFor={goal}
                className="ml-2 block text-sm text-gray-700 dark:text-gray-300"
              >
                {t(`contentStrategyForm.${goal.toLowerCase().replace(/\s+/g, '')}`)}
              </label>
            </div>
          ))}
        </div>
        {errors.goals && (
          <p className="mt-1 text-sm text-red-600 flex items-center dark:text-red-400">
            <ExclamationCircleIcon className="h-4 w-4 mr-1"/>
            {errors.goals.message}
          </p>
        )}
      </div>

      <FormSelect
        name="resources.contentCreation"
        label={t('contentStrategyForm.contentCreationResources')}
        tipText={t('contentStrategyForm.contentCreationResourcesInfo')}
        error={errors.resources?.contentCreation}
        validation={{
          required: {
            value: true,
            message: t('contentStrategyForm.selectResourceLevel')
          }
        }}
        options={[
          {value: "", label: t('contentStrategyForm.selectResourceLevelPlaceholder')},
          {value: "solo", label: t('contentStrategyForm.solo')},
          {value: "small-team", label: t('contentStrategyForm.smallTeam')},
          {value: "large-team", label: t('contentStrategyForm.largeTeam')},
          {value: "agency", label: t('contentStrategyForm.agency')}
        ]}
      />
    </div>
  );

  const renderStepIndicator = () => (
    <div className="mb-8">
      <div className="flex flex-col sm:flex-row justify-between gap-4">
        {[1, 2, 3].map((stepNumber) => (
          <div
            key={stepNumber}
            className={`flex items-center transition-all duration-200 ${
              stepNumber < step
                ? 'text-violet-600 dark:text-violet-400'
                : step === stepNumber
                  ? 'text-violet-600 dark:text-violet-400'
                  : 'text-gray-400 dark:text-gray-500'
            }`}
          >
            <div
              className={`
              relative flex items-center justify-center w-8 h-8 rounded-full 
              border-2 transition-all duration-200
              ${stepNumber <= step
                ? 'border-violet-500 dark:border-violet-400 bg-violet-50 dark:bg-violet-950/50'
                : 'border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800'
              }
              ${step === stepNumber && 'ring-4 ring-violet-100 dark:ring-violet-900'}
            `}
            >
              {stepNumber < step ? (
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  className="w-4 h-4 text-violet-600 dark:text-violet-400 animate-in fade-in duration-300"
                >
                  <path
                    d="M20 6L9 17L4 12"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <span className={`text-sm font-semibold ${
                  stepNumber === step
                    ? 'text-violet-600 dark:text-violet-400'
                    : 'text-gray-500 dark:text-gray-400'
                }`}>{stepNumber}</span>
              )}
            </div>
            <span
              className={`ml-3 text-sm font-medium transition-colors duration-200 ${
                stepNumber <= step ? 'text-gray-900 dark:text-gray-100' : 'text-gray-500 dark:text-gray-400'
              }`}
            >
            {t(`contentStrategyForm.step${stepNumber}`)}
          </span>
          </div>
        ))}
      </div>
      <div className="mt-4">
        <div className="overflow-hidden h-2 rounded-full bg-gray-200 dark:bg-gray-800">
          <div
            className="h-full rounded-full bg-violet-500 dark:bg-violet-400 transition-all duration-500 ease-in-out"
            style={{width: `${((step - 1) / 2) * 100}%`}}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full max-w-2xl mx-auto px-4 sm:px-6 py-4 sm:py-6">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>

        {/* ####### HEADER ######## */}
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 dark:bg-gray-800">
          <h2 className="text-lg sm:text-xl font-bold text-gray-900 mb-1 dark:text-gray-100">
            {t('contentStrategyForm.title')}
          </h2>
          <p className={"text-sm text-gray-500 dark:text-gray-400 mb-6 "}>
            {t('contentStrategyForm.description')}
          </p>

          {/* ####### STEPS ######## */}
          {renderStepIndicator()}


          {/* ####### STEP RENDER ######## */}
          {step === 1 && renderBusinessTypeStep()}
          {step === 2 && renderAudienceStep()}
          {step === 3 && renderGoalsStep()}


          {/* ####### BUTTONS ######## */}
          <div className="mt-8 flex flex-col sm:flex-row gap-4 sm:gap-0 justify-between">
            {step > 1 && (
              <button
                type="button"
                onClick={() => validateStep(step - 1)}
                className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600"
              >
                <ChevronLeftIcon className="h-4 w-4 mr-2"/>
                {t('contentStrategyForm.previous')}
              </button>
            )}
            {step < 3 ? (
              <button
                type="button"
                onClick={() => validateStep(step + 1)}
                className={`inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                  step === 1 ? 'ml-auto' : 'w-full sm:w-auto sm:ml-auto'
                }`}
              >
                {t('contentStrategyForm.next')}
                <ChevronRightIcon className="h-4 w-4 ml-2"/>
              </button>
            ) : (
              <button
                type="submit"
                className="w-full sm:w-auto sm:ml-auto inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:focus:ring-offset-gray-800"
              >
                {t('contentStrategyForm.getRecommendations')}
                <CheckCircleIcon className="h-4 w-4 ml-2"/>
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContentStrategyForm;
import React from "react";
import Meta from "./../components/Meta";
import {requireAuth, useAuth} from "./../util/auth";
import {useTranslation} from "react-i18next";
import OnboardingSection from "../components/Onboarding/OnboardingSection";
import {useHistory} from "react-router-dom";

function OnboardingPage(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };


  const auth = useAuth();
  const history = useHistory();

  // useEffect(() => {
  //   if (auth.user.isnew === true) {
  //     history.push("/onboarding/welcome");
  //   }
  // }, [auth, history]);


  return (
    <>
      <Meta title="Onboarding"/>
      <OnboardingSection
        title={t("onboarding.title_page")}
        subtitle="" //{t("Dashboard_page_description")}
        strapline=""
        size="md"
        bgColor=""
      />
    </>
  );
}

export default requireAuth(OnboardingPage);

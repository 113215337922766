import React from "react";
import Meta from "./../components/Meta";
import SettingsSection from "./../components/SettingsSection";
import {useRouter} from "./../util/router";
import {requireAuth} from "./../util/auth";
import {useTranslation} from "react-i18next";

function SettingsPage(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  const router = useRouter();

  return (
    <>
      <Meta title="Settings"/>
      <SettingsSection
        size="md"
        bgColor=""
        bgImage=""
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />
    </>
  );
}

export default requireAuth(SettingsPage);

import React, {useEffect, useState} from 'react';
import {useAuth} from "../../util/auth";

import {useTranslation} from "react-i18next";
import {SocialIcon} from 'react-social-icons';
import log from "../../util/logger";
import LoadingAnimation from "../LoadingAnimation";

function SocialNetworks() {
  const {t, i18n} = useTranslation();
  const auth = useAuth();

  // Initialize localCompany with socials as an empty array to avoid undefined errors
  const [localCompany, setLocalCompany] = useState({socials: []});
  const [loading, setLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(() => {
    const storedIsFirstLoad = localStorage.getItem('isFirstLoad');
    return storedIsFirstLoad !== null ? JSON.parse(storedIsFirstLoad) : true;
  });

  useEffect(() => {
    if (auth.defaultCompany) {
      log.debug('SocialNetworks: defaultCompany updated:', auth.defaultCompany);
      if (isFirstLoad) {
        setTimeout(() => {
          log.debug('SocialNetworks: defaultCompany updated after delay:', auth.defaultCompany);
          setLocalCompany({...auth.defaultCompany});
          setLoading(false);
          setIsFirstLoad(false);
          localStorage.setItem('isFirstLoad', JSON.stringify(false));

        }, 1000);
      } else {
        log.debug('SocialNetworks: defaultCompany updated:', auth.defaultCompany);
        setLocalCompany({...auth.defaultCompany});
        setLoading(false);
        setIsFirstLoad(false);
      }
    }
  }, [auth.defaultCompany]);

  if (loading) {
    return (
      <LoadingAnimation/>
    );
  }

  return (
    <div className="col-span-full xl:col-span-8 bg-white dark:bg-gray-800 shadow-sm rounded-xl">
      <header className="px-5 py-4 border-b border-gray-100 dark:border-gray-700/60">
        <h2
          className="font-semibold text-gray-800 dark:text-gray-100">{t("dashboard.socials")} {auth?.defaultCompany?.name}</h2>
      </header>
      <div className="p-3">
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-gray-300">
            <tbody className="text-sm font-medium divide-y divide-gray-100 dark:divide-gray-700/60">
            {(!localCompany?.socials || localCompany?.socials?.length === 0) ? (
              <tr>
                <td className="p-2" colSpan="2">
                  <div className="text-center text-gray-500 dark:text-gray-400">
                    {t("dashboard.noSocials")}
                  </div>
                </td>
              </tr>
            ) : (
              localCompany?.socials?.map((social, index) => (
                <tr key={index}>
                  <td className="p-2">
                    <div className="flex items-center">
                      <SocialIcon
                        url={social?.profile_url ?? ''}
                        target="_blank"
                        className="shrink-0 mr-2 sm:mr-3"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                      />
                      <div className="text-gray-800 dark:text-gray-100 text-left">
                        {social?.platform_name ?? 'N/A'}
                      </div>
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="text-left">{social?.profile_url ?? 'N/A'}</div>
                  </td>
                </tr>
              ))
            )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SocialNetworks;

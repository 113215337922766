import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Auth from "./Auth";
import AuthFooter from "./AuthFooter";
import {useTranslation} from "react-i18next";
import log from "../util/logger";

function AuthSection(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  // get the query next= value
  const next = props.afterAuthPath;
  log.info("next: " + next);

  // Options by auth type
  const optionsByType = {
    signup: {
      // Top Title
      title: t("Signup_title"),
      // Button text
      buttonAction: t("buttonAction"),
      // Footer text and links
      showFooter: true,
      signinText: t("signinText"),
      signinAction: t("signinAction"),
      signinPath: "/auth/signin",
      // Terms and privacy policy agreement
      showAgreement: true,
      termsPath: "/legal/terms-of-service",
      privacyPolicyPath: "/legal/privacy-policy",
      afterAuthPath: props.afterAuthPath || "/dashboard",
    },
    signin: {
      title: t("Signin_title"),
      buttonAction: t("signinAction"),
      showFooter: true,
      signupAction: t("signupAction"),
      signupPath: "/auth/signup",
      forgotPassAction: t("forgotPassAction"),
      forgotPassPath: "/auth/forgotpass",
    },
    forgotpass: {
      title: t("forgotpass_title"),
      buttonAction: t("forgotbuttonAction"),
      showFooter: true,
      signinText: t("forgotsigninText"),
      signinAction: t("signinAction"),
      signinPath: "/auth/signin",
    },
    changepass: {
      title: t("changepass_title"),
      buttonAction: t("changepass_buttonAction"),
    },
  };

  // Ensure we have a valid auth type
  const type = optionsByType[props.type] ? props.type : "signup";

  // Get options object for current auth type
  const options = optionsByType[type];

  // Set default afterAuthPath and override for signup
  const afterAuthPath = type === "signup" ? options.afterAuthPath : props.afterAuthPath;


  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container max-w-md">
        <SectionHeader
          title={options.title}
          subtitle=""
          strapline=""
          className="text-center"
        />
        <Auth
          type={type}
          buttonAction={options.buttonAction}
          providers={props.providers}
          afterAuthPath={afterAuthPath}
          key={type}
        />

        {options.showFooter && <AuthFooter type={type} {...options} />}
      </div>
    </Section>
  );
}

export default AuthSection;

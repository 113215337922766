// src/i18n.js
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

// Optionally, load translation files dynamically using HTTP backend
// import Backend from 'i18next-http-backend';
// Detect the user's language using the browser's settings
import LanguageDetector from 'i18next-browser-languagedetector';
//
import translationEN from "../locales/en/translation.json";
import translationPT from "../locales/pt/translation.json";
import translationES from "../locales/es/translation.json";
//

i18n
  // .use(Backend) // Enable if you use backend loading for translations
  .use(LanguageDetector) // Automatically detect the language
  .use(initReactI18next) // Connect with React
  .init({
    resources: {
      en: {...translationEN},
      pt: {...translationPT},
      es: {...translationES},
    },
    fallbackLng: 'en', // Fallback language if the detected language is not available
    debug: false, // Set to false in production
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });


export default i18n;

import React from "react";
import Meta from "./../components/Meta";
import {requireAuth, useAuth} from "./../util/auth";
import ContentSection from "../components/ContentSection";
import {useTranslation} from "react-i18next";

function ContentPage(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };



  const auth = useAuth();

  return (
    <>
      <Meta title="Content Hub"/>
      <ContentSection
        title={t("Content_page_title")}
        subtitle={t("Content_page_description")}
        strapline=""
        size="sm"
        bgColor=""
      />
    </>
  );
}

export default requireAuth(ContentPage);

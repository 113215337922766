function AboutPage(props) {

  window.location.href = "https://www.sunokrom.com/about";
  // useEffect(() => {
  //   window.location.href = "https://www.sunokrom.com/about";
  // }, []);
  // <Link to={{pathname: "https://www.sunokrom.com"}} className={`${classes.navLink}`} target="_blank">
  return null;
}

export default AboutPage;

// Header.js
import React from "react";
import {useTranslation} from "react-i18next";

function Header() {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  return (
    <div className="flex items-center justify-between h-16 px-0 sm:px-6 lg:px-8">
      <div className="flex items-center pt-5">
        <h1 className="text-xl lg:text-2xl text-gray-800 dark:text-gray-100 font-bold">
          {t('onboarding.title')}
        </h1>
      </div>
    </div>
  );
}

export default Header;

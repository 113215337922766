import {Link} from "react-router-dom";
import React from "react";
import {TrashIcon} from "@heroicons/react/24/solid";
import {motion} from "framer-motion";


function ContentIdea(props) {

  // Add this useEffect to handle body overflow


  return (
    <motion.div
      initial={{opacity: 0, y: 20}}
      animate={{opacity: 1, y: 0}}
      exit={{
        opacity: 0,
        x: -300,  // Slide out to the left
        transition: {
          duration: 0.4,
          ease: "easeOut"
        }
      }}
      transition={{duration: 0.3}}
      layout  // Add this to animate remaining items
    >
      <article className="bg-white dark:bg-gray-800 shadow-sm rounded-xl p-5">
        <div className="flex flex-start space-x-4">
          {/* Avatar */}
          <div className="shrink-0 mt-1.5">
            {/*<img className="w-8 h-8 rounded-full" src={UserAvatar} width="32" height="32" alt="User avatar"/>*/}
            <span className="text-xl">{props.idea_emoji}</span>
          </div>
          {/* Content */}
          <div className="grow">
            {/* Title */}
            <h2 className="font-semibold text-gray-800 dark:text-gray-100 mb-2">
              <Link
                to="#"
                onClick={(e) => {
                  e.stopPropagation();
                  props.updateModal(props.index);
                }}
              >
                {props.idea}
              </Link>
            </h2>
            {/* Footer */}
            <footer className="flex flex-wrap text-sm">
              <div
                className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-gray-400 dark:after:text-gray-600 after:px-2">
                <span className="text text-gray-800 dark:text-gray-300">
                  {props.summary}
                </span>
              </div>
              <div
                className="flex items-center after:block after:content-['·'] last:after:content-[''] text-sm after:text-gray-400 dark:after:text-gray-600 after:px-2">
                <div className="flex items-center">
                  {/*<svg className="mr-2 fill-current" width="16" height="16" xmlns="http://www.w3.org/2000/svg">*/}
                  {/*  <path*/}
                  {/*    d="M15.686 5.708 10.291.313c-.4-.4-.999-.4-1.399 0s-.4 1 0 1.399l.6.6-6.794 3.696-1-1C1.299 4.61.7 4.61.3 5.009c-.4.4-.4 1 0 1.4l1.498 1.498 2.398 2.398L.6 14.001 2 15.4l3.696-3.697L9.692 15.7c.5.5 1.199.2 1.398 0 .4-.4.4-1 0-1.4l-.999-.998 3.697-6.695.6.6c.599.6 1.199.2 1.398 0 .3-.4.3-1.1-.1-1.499Zm-7.193 6.095L4.196 7.507l6.695-3.697 1.298 1.299-3.696 6.694Z"/>*/}
                  {/*</svg>*/}
                  <span className="text-sm text-gray-500">{props.content_type}</span>
                </div>
              </div>
              {/*<div*/}
              {/*  className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-gray-400 dark:after:text-gray-600 after:px-2">*/}
              {/*  <span className="text-gray-500">688 Comments</span>*/}
              {/*</div>*/}
            </footer>
          </div>
          {/* Upvote button */}
          <div className="shrink-0">
            <button
              className="text-xs font-semibold text-center h-9 w-9 border border-violet-500/10 rounded-lg flex flex-col justify-center items-center"
              onClick={props.onRemove}
            >

              <TrashIcon className="w-4 text-red-500"/>
            </button>
          </div>
        </div>
      </article>


    </motion.div>


  );
}

export default ContentIdea;
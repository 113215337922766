import React, {useState} from 'react';
import UserAvatar from '../../images/company-logo-generic-dark.png';
import ModalBlank from "../ModalBlank";

import log from "../../util/logger"

function ProfileHeader(props) {
  const [dangerModalOpen, setDangerModalOpen] = useState(false);

  return (
    <div
      className="grow flex flex-col md:translate-x-0 transition-transform duration-300 ease-in-out translate-x-0 bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden"
    >
      {/* Profile background */}
      {/*<div className="relative h-56 bg-gray-200 dark:bg-gray-900">*/}
      {/*  <img className="object-cover h-full w-full" src={props.Background || ProfileBg2} width="979" height="220"*/}
      {/*       alt="Profile background"/>*/}
      {/*</div>*/}

      {/* Content */}
      <div className="relative px-4 sm:px-6 sm:pb-2">
        {/* Pre-header */}
        <div className="mt-1 mb-2 ">
          <div className="flex flex-col items-center sm:flex-row ">

            {/* Avatar */}
            <div className="inline-flex  mb-1 sm:mb-0">
              <div className="bg-white rounded-full">
                <img
                  className="max-w-[24rem] rounded-full border-2 border-gray-200 dark:border-gray-900 object-cover w-[72px] h-[72px] sm:w-[96px] sm:h-[96px] md:w-[128px] md:h-[128px]"
                  src={props.UserAvatar || UserAvatar}
                  width="128"
                  height="128"
                  alt="Avatar"
                />

              </div>

            </div>

            {/* Company Name */}
            <header className="text-center sm:text-left md:ml-3 mb-1">
              {/* Name */}
              <div className="inline-flex items-start mb-2">
                <h1 className="text-2xl text-gray-800 dark:text-gray-100 font-bold">
                  {props.CompanyName || "Your Company"}
                </h1>
                <svg className="fill-current shrink-0 text-yellow-500 ml-2" width="16" height="16" viewBox="0 0 16 16">
                  <path
                    d="M13 6a.75.75 0 0 1-.75-.75 1.5 1.5 0 0 0-1.5-1.5.75.75 0 1 1 0-1.5 1.5 1.5 0 0 0 1.5-1.5.75.75 0 1 1 1.5 0 1.5 1.5 0 0 0 1.5 1.5.75.75 0 1 1 0 1.5 1.5 1.5 0 0 0-1.5 1.5A.75.75 0 0 1 13 6ZM6 16a1 1 0 0 1-1-1 4 4 0 0 0-4-4 1 1 0 0 1 0-2 4 4 0 0 0 4-4 1 1 0 1 1 2 0 4 4 0 0 0 4 4 1 1 0 0 1 0 2 4 4 0 0 0-4 4 1 1 0 0 1-1 1Z"/>
                </svg>
              </div>

              {/* Bio */}
              <div className="text-sm mb-3">
                {props.CompanyBio || "Here you can describe your company in a few words."}
              </div>
              {/* Meta */}
              <div className="flex flex-wrap justify-center sm:justify-start space-x-4">

                {props.CompanyLocation && (
                  <>
                    <div className="flex items-center">
                      <svg className="fill-current shrink-0 text-gray-400 dark:text-gray-500" width="16" height="16"
                           viewBox="0 0 16 16">
                        <path
                          d="M8 8.992a2 2 0 1 1-.002-3.998A2 2 0 0 1 8 8.992Zm-.7 6.694c-.1-.1-4.2-3.696-4.2-3.796C1.7 10.69 1 8.892 1 6.994 1 3.097 4.1 0 8 0s7 3.097 7 6.994c0 1.898-.7 3.697-2.1 4.996-.1.1-4.1 3.696-4.2 3.796-.4.3-1 .3-1.4-.1Zm-2.7-4.995L8 13.688l3.4-2.997c1-1 1.6-2.198 1.6-3.597 0-2.798-2.2-4.996-5-4.996S3 4.196 3 6.994c0 1.399.6 2.698 1.6 3.697 0-.1 0-.1 0 0Z"/>
                      </svg>
                      <span className="text-sm font-medium whitespace-nowrap text-gray-500 dark:text-gray-400 ml-2">
                {props.CompanyLocation || "Your Location"}
              </span>
                    </div>
                  </>
                )}

                <div className="flex items-center">
                  <svg className="fill-current shrink-0 text-gray-400 dark:text-gray-500" width="16" height="16"
                       viewBox="0 0 16 16">
                    <path
                      d="M11 0c1.3 0 2.6.5 3.5 1.5 1 .9 1.5 2.2 1.5 3.5 0 1.3-.5 2.6-1.4 3.5l-1.2 1.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l1.1-1.2c.6-.5.9-1.3.9-2.1s-.3-1.6-.9-2.2C12 1.7 10 1.7 8.9 2.8L7.7 4c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l1.2-1.1C8.4.5 9.7 0 11 0ZM8.3 12c.4-.4 1-.5 1.4-.1.4.4.4 1 0 1.4l-1.2 1.2C7.6 15.5 6.3 16 5 16c-1.3 0-2.6-.5-3.5-1.5C.5 13.6 0 12.3 0 11c0-1.3.5-2.6 1.5-3.5l1.1-1.2c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L2.9 8.9c-.6.5-.9 1.3-.9 2.1s.3 1.6.9 2.2c1.1 1.1 3.1 1.1 4.2 0L8.3 12Zm1.1-6.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4.2 4.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l4.2-4.2Z"/>
                  </svg>
                  <p
                    className="text-sm font-medium whitespace-nowrap text-gray-800 dark:text-gray-400 ml-2"
                    href="#0">
                    {props.CompanyWebsite || "YourWebsite.com"}
                  </p>
                </div>
              </div>
            </header>
          </div>
        </div>


      </div>
      {/* Actions */}
      {/*<div className="flex justify-end space-x-2  sm:mb-2 relative">*/}
      {/*  <DropdownEditCompany align="right" className="relative inline-flex shrink-0">*/}
      {/*    /!*<li>*!/*/}
      {/*    /!*  <Link className="font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-1" to="#0">Option 1</Link>*!/*/}
      {/*    /!*</li>*!/*/}
      {/*    /!*<li>*!/*/}
      {/*    /!*  <Link className="font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-1" to="#0">Option 2</Link>*!/*/}
      {/*    /!*</li>*!/*/}
      {/*    <li>*/}
      {/*      <Link*/}
      {/*        className="font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-1"*/}
      {/*        to="#0"*/}
      {/*        onClick={(e) => {*/}
      {/*          setDangerModalOpen(true);*/}
      {/*          e.stopPropagation();*/}
      {/*        }}*/}
      {/*      >Remove</Link>*/}
      {/*    </li>*/}
      {/*  </DropdownEditCompany>*/}
      {/*</div>*/}

      <ModalBlank id="danger-modal" modalOpen={dangerModalOpen} setModalOpen={setDangerModalOpen}>
        <div className="p-5 flex space-x-4 relative z-50">
          {/* Icon */}
          <div
            className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-gray-100 dark:bg-gray-700">
            <svg className="shrink-0 fill-current text-red-500" width="16" height="16" viewBox="0 0 16 16">
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"/>
            </svg>
          </div>
          {/* Content */}
          <div>
            {/* Modal header */}
            <div className="mb-2">
              <div className="text-lg font-semibold text-gray-800 dark:text-gray-100">Delete {props.CompanyName} profile
                FOREVER?
              </div>
            </div>
            {/* Modal content */}
            <div className="text-sm mb-10">
              <div className="space-y-2">
                <p>This action will delete the company profile and is IRREVERSIBLE. Are you sure? </p>
              </div>
            </div>
            {/* Modal footer */}
            <div className="flex flex-wrap justify-end space-x-2">
              <button
                className="btn-sm border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 text-gray-800 dark:text-gray-300"
                onClick={(e) => {
                  e.stopPropagation();
                  setDangerModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button className="btn-sm bg-red-500 hover:bg-red-600 text-white"
                      onClick={(e) => {
                        // Delete the company profile
                        log.warn(`Deleting ${props.CompanyName} profile...`);
                        // Close the modal
                        setDangerModalOpen(false);
                        e.stopPropagation();
                      }}
              >Yes, Delete it
              </button>
            </div>
          </div>
        </div>
      </ModalBlank>
    </div>
  );
}

export default ProfileHeader;

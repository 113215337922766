import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";
import {useTranslation} from "react-i18next";

function PricingPage(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        title={t("pricing.page.title")}
        subtitle={t("pricing.page.subtitle")}
        strapline={t("pricing.page.strapline")}
        size="md"
        bgColor=""
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default PricingPage;

import React from "react";
import {useRouter} from "../../util/router";
import log from "../../util/logger";
import OnboardingCards from "./OnboardingCards";
import Onboarding01 from "./Onboarding01";
import Meta from "../Meta";
import {useTranslation} from "react-i18next";
import Section from "../Section";
import SectionHeader from "../SectionHeader";

function OnboardingSection(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  const router = useRouter();
  const {slug} = router.query || {};
  log.debug("OnboardingPage slug ", slug);

  const renderComponent = () => {
    switch (slug) {
      case 'welcome':
        log.debug("OnboardingPage slug ", slug);
        return <OnboardingCards/>;
      default:
        log.debug("OnboardingPage slug DEFAULT selected ");
        return <Onboarding01/>;
    }
  };

  return (
    <>
      <Meta title="Onboarding"/>
      <Section
        size={props.size}
        bgColor={props.bgColor}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        textColor={props.textColor}
        className={"mt-0 pt-0"}
      >
        {(slug && slug.length > 3) && (
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            strapline={props.strapline}
            className="text-center mb-4"
          />
        )}

        <div className="container">

          {renderComponent()}

        </div>
      </Section>
    </>
  );
}


export default OnboardingSection;

import React from "react";
import Section from "./Section";
import {useAuth} from "./../util/auth";
import {useTranslation} from "react-i18next";
import ToolsItems from "./ToolsItems";


function ToolsSection(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  const auth = useAuth();


  return (

    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      className={"mt-0 pt-0 px-0"}
    >

      <div className="container">
        <ToolsItems/>
      </div>
    </Section>
  );
}

export default ToolsSection;

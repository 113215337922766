import {QueryClient, QueryClientProvider as QueryClientProviderBase, useQuery,} from "@tanstack/react-query";
import {apiRequest} from "./util";

// React Query client
const client = new QueryClient();


// ================== OPEN AI CALL ---END ==================


/**** USERS ****/

// Fetch user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", {uid}],
    // Query function that fetches data
    () => apiRequest(`user-get?uid=${uid}`),
    // Only call query function if we have a `uid`
    {enabled: !!uid}
  );
}

// Fetch user data (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return apiRequest(`user-get?uid=${uid}`);
}

// Create a new user
export async function createUser(uid, data) {
  const response = await apiRequest("user-create", "POST", {uid, ...data});
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["user", {uid}]);
  return response;
}

// Update an existing user
export async function updateUser(uid, data) {
  const response = await apiRequest(`user-update?uid=${uid}`, "PATCH", data);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["user", {uid}]);
  return response;
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Fetch item data
export function useItem(id) {
  return useQuery(["item", {id}], () => apiRequest(`item-get?id=${id}`), {
    enabled: !!id,
  });
}

// Fetch all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", {owner}],
    () => apiRequest(`items-get?owner=${owner}`),
    {enabled: !!owner}
  );
}

// Create a new item
export async function createItem(data) {
  const response = await apiRequest("item-create", "POST", data);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["items"]);
  return response;
}

// Update an item
export async function updateItem(id, data) {
  const response = await apiRequest(`item-update?id=${id}`, "PATCH", data);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["item", {id}]),
    client.invalidateQueries(["items"]),
  ]);
  return response;
}

// Delete an item
export async function deleteItem(id) {
  const response = await apiRequest(`item-delete?id=${id}`, "DELETE");
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["item", {id}]),
    client.invalidateQueries(["items"]),
  ]);
  return response;
}


// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}

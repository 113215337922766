import React, {useEffect, useState} from "react";
import {RingLoader} from "react-spinners";
import {AnimatePresence, motion} from "framer-motion";
import {useTranslation} from 'react-i18next';

// Custom hook for random loading messages
function useRandomLoadingMessage(messages, interval) {
  const [loadingMessage, setLoadingMessage] = useState(messages[0] || ''); // Set initial message

  useEffect(() => {
    const messageInterval = setInterval(() => {
      const randomMessage = messages[Math.floor(Math.random() * messages.length)];
      setLoadingMessage(randomMessage);
    }, interval);

    return () => clearInterval(messageInterval); // Cleanup on unmount
  }, [messages, interval]);

  return loadingMessage;
}

function LoadingAnimation(props) {
  const {t} = useTranslation();

  // Retrieve translated loading messages
  const loadingMessages = t('loading_messages', {returnObjects: true});

  // Use custom hook to get a new message every 3 seconds
  const randomMessage = useRandomLoadingMessage(loadingMessages, 7000);

  return (
    <div className="flex flex-col justify-center items-center p-2 pb-1">
      <div className="relative text-sm p-2 pb-0 h-6 w-64">
        <AnimatePresence>
          <motion.div
            key={randomMessage} // Set key to trigger animation when message changes
            initial={{opacity: 0, y: 20}} // Start below
            animate={{opacity: 1, y: 0}} // Fade and move up
            exit={{opacity: 0, y: -20}} // Fade out and move up
            transition={{duration: 0.5}}
            className="absolute w-full text-center text-gray-800 dark:text-gray-100"
          >
            {'✨' + randomMessage}
          </motion.div>
        </AnimatePresence>
      </div>
      <RingLoader color="#5562b4"/>
    </div>
  );
}

export default LoadingAnimation;

import React from 'react';
import {motion} from "framer-motion";

function TextMotion(props) {
  return (
    <>
      {props.effect === "wave" && (
        <motion.div
          className="text-2xl text-gray-800 dark:text-gray-300"
          initial="hidden"
          animate="visible"
          variants={{
            visible: {
              transition: {
                staggerChildren: 0.05, // Staggers the letters
              },
            },
          }}
        >
          {props.text.split("").map((letter, index) => (
            <motion.span
              key={index}
              variants={{
                hidden: {opacity: 0, y: 20},
                visible: {opacity: 1, y: 0},
              }}
              transition={{
                duration: 0.4,
                repeat: Infinity, // Repeats each letter animation indefinitely
                repeatType: "reverse", // Reverses the animation (smooth loop)
                ease: "easeInOut", // Ensures a smooth transition
              }}
            >
              {letter}
            </motion.span>
          ))}
        </motion.div>
      )}
      {props.effect === "flicker" && (
        <motion.p
          className="text-2xl text-gray-800 dark:text-gray-300"
          initial={{color: "#579ff3"}}
          animate={{color: "#7c02ff"}}
          transition={{duration: 0.8, repeat: Infinity, repeatType: "mirror"}}
        >
          {props.text}
        </motion.p>
      )}
    </>
  );
}

export default TextMotion;

import React from "react";
import Meta from "./../components/Meta";
import Section from "../components/Section";
import FaqSection from "../components/FaqSection";
import {useTranslation} from "react-i18next";

// import report from "../reporttest.html"

function FaqPage(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  return (
    <>
      <Meta title="Faq" />
      <Section bgColor="white">
        {/*<ReportTest />*/}
        {/*<DynamicHtmlComponent htmlFilePath={report} />*/}
      </Section>
      <FaqSection
        title={t("faq.title")}
        subtitle=""
        strapline=""
        size="md"
        bgColor=""
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        showSupportButton={true}
        supportUrl="https://zendesk.com"
      />
      {/*<CtaSection*/}
      {/*  title={*/}
      {/*    <>*/}
      {/*      Create an account <span className="text-blue-600">today</span>!*/}
      {/*    </>*/}
      {/*  }*/}
      {/*  subtitle="Inspiring results from day one without the pain. Get your own custom dashboard and start building amazing services."*/}
      {/*  strapline=""*/}
      {/*  size="md"*/}
      {/*  bgColor=""*/}
      {/*  bgImage=""*/}
      {/*  bgImageOpacity={1}*/}
      {/*  textColor=""*/}
      {/*/>*/}
    </>
  );
}

export default FaqPage;

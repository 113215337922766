import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Flag from 'react-world-flags';
import {LanguageIcon} from "@heroicons/react/24/outline";

const LanguageSelectorBig = () => {
  const {t, i18n} = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };

  const flagSize = {width: '48px', height: '48px'}

  return (
    <div className="w-full p-4">
      <div className="flex flex-col justify-center text-center">
        <h2 className="text-md font-semibold mb-4">
          Which language do you prefer?
        </h2>
        <h2 className="text-md font-semibold mb-4">
          Qual idioma você prefere?
        </h2>
        <h2 className="text-md font-semibold mb-4">
          ¿Qué idioma prefieres?
        </h2>
      </div>

      <div className="flex gap-4">
        {/* English Block */}
        <label className="flex-1 relative block cursor-pointer">
          <input
            type="radio"
            name="language"
            value="en"
            className="peer sr-only"
            checked={selectedLanguage === 'en'}
            onChange={() => changeLanguage('en')}
          />
          <div
            className="h-full text-center bg-white dark:bg-gray-800 px-2 py-3 rounded-lg border border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 shadow-sm transition"
          >
            <Flag code="us" className="inline-flex mt-2 mb-4" style={flagSize}/>
            <div className="font-semibold text-gray-800 dark:text-gray-100 mb-1">
              English
            </div>
          </div>
          <div
            className="absolute inset-0 border-2 border-transparent peer-checked:border-blue-400 rounded-lg pointer-events-none"
            aria-hidden="true"
          ></div>
        </label>

        {/* Portuguese Block */}
        <label className="flex-1 relative block cursor-pointer">
          <input
            type="radio"
            name="language"
            value="pt"
            className="peer sr-only"
            checked={selectedLanguage === 'pt'}
            onChange={() => changeLanguage('pt')}
          />
          <div
            className="h-full text-center bg-white dark:bg-gray-800 px-2 py-3 rounded-lg border border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 shadow-sm transition"
          >
            <Flag code="br" className="inline-flex mt-2 mb-4" style={flagSize}/>
            <div className="font-semibold text-gray-800 dark:text-gray-100 mb-1">
              Português
            </div>
          </div>
          <div
            className="absolute inset-0 border-2 border-transparent peer-checked:border-green-400 rounded-lg pointer-events-none"
            aria-hidden="true"
          ></div>
        </label>

        {/* Spanish Block */}
        <label className="flex-1 relative block cursor-pointer">
          <input
            type="radio"
            name="language"
            value="es"
            className="peer sr-only"
            checked={selectedLanguage === 'es'}
            onChange={() => changeLanguage('es')}
          />
          <div
            className="h-full text-center bg-white dark:bg-gray-800 px-2 py-3 rounded-lg border border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 shadow-sm transition"
          >
            <Flag code="es" className="inline-flex mt-2 mb-4" style={flagSize}/>
            <div className="font-semibold text-gray-800 dark:text-gray-100 mb-1">
              Español
            </div>
          </div>
          <div
            className="absolute inset-0 border-2 border-transparent peer-checked:border-red-400 rounded-lg pointer-events-none"
            aria-hidden="true"
          ></div>
        </label>
      </div>

      <div className="flex flex-col sm:flex-row items-center justify-center mt-4 space-y-0">
        <p className="text-sm text-gray-500 dark:text-gray-400 sm:mr-2">
          {t("you_can_change_language")}
        </p>
        <LanguageIcon className="w-4 h-4"/>
      </div>


    </div>
  );
};

export default LanguageSelectorBig;

import React, {useCallback, useRef, useState} from 'react';
import {ArcElement, Chart, Legend, PieController, Tooltip} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {useThemeProvider} from '../utils/ThemeContext';
import {chartColors} from './ChartjsConfig';
import {tailwindConfig} from '../utils/Utils';

Chart.register(PieController, ArcElement, Tooltip, Legend, ChartDataLabels);

function PieChart({data, width, height}) {
  const [chart, setChart] = useState(null);
  const legend = useRef(null);
  const {currentTheme} = useThemeProvider();
  const darkMode = currentTheme === 'dark';
  const {tooltipTitleColor, tooltipBodyColor, tooltipBgColor, tooltipBorderColor} = chartColors;

  const canvasRef = useCallback((node) => {
    if (node !== null) {
      const ctx = node.getContext('2d');

      if (chart) chart.destroy();

      const newChart = new Chart(ctx, {
        type: 'pie',
        data: data,
        options: {
          layout: {
            padding: {
              top: 4,
              bottom: 4,
              left: 24,
              right: 24,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              titleColor: darkMode ? tooltipTitleColor.dark : tooltipTitleColor.light,
              bodyColor: darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light,
              backgroundColor: darkMode ? tooltipBgColor.dark : tooltipBgColor.light,
              borderColor: darkMode ? tooltipBorderColor.dark : tooltipBorderColor.light,
            },
            datalabels: {
              color: darkMode ? '#fff' : '#000',
              formatter: (value, context) => {
                const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
                const percentage = Math.round((value / total) * 100) + '%';
                return percentage;
              },
            },
          },
          interaction: {
            intersect: false,
            mode: 'nearest',
          },
          animation: {
            duration: 200,
          },
          maintainAspectRatio: false,
        },
        plugins: [
          {
            id: 'htmlLegend',
            afterUpdate(c) {
              const ul = legend.current;
              if (!ul) return;
              while (ul.firstChild) {
                ul.firstChild.remove();
              }

              const items = c.options.plugins.legend.labels.generateLabels(c);
              items.forEach((item) => {
                const li = document.createElement('li');
                li.style.margin = tailwindConfig().theme.margin[1.5];
                const button = document.createElement('button');
                button.style.display = 'inline-flex';
                button.style.alignItems = 'center';
                button.style.opacity = item.hidden ? '.3' : '';
                button.onclick = () => {
                  c.toggleDataVisibility(item.index);
                  c.update();
                };

                const box = document.createElement('span');
                box.style.display = 'block';
                box.style.width = tailwindConfig().theme.width[3];
                box.style.height = tailwindConfig().theme.height[3];
                box.style.borderRadius = tailwindConfig().theme.borderRadius.full;
                box.style.marginRight = tailwindConfig().theme.margin[1.5];
                box.style.borderWidth = '3px';
                box.style.borderColor = item.fillStyle;
                box.style.pointerEvents = 'none';

                const label = document.createElement('span');
                label.classList.add('text-gray-500', 'dark:text-gray-400');
                label.style.fontSize = tailwindConfig().theme.fontSize.xs[0];
                label.style.lineHeight = tailwindConfig().theme.fontSize.xs[1].lineHeight;
                const labelText = document.createTextNode(item.text);
                label.appendChild(labelText);

                li.appendChild(button);
                button.appendChild(box);
                button.appendChild(label);
                ul.appendChild(li);
              });
            },
          },
        ],
      });

      setChart(newChart);
    }
  }, [data, darkMode, tooltipTitleColor, tooltipBodyColor, tooltipBgColor, tooltipBorderColor]);

  return (
    <div className="grow flex flex-col justify-center">
      <div>
        <canvas ref={canvasRef} width={width} height={height}></canvas>
      </div>
      <div className="px-1 py-0 hidden sm:block">
        <ul ref={legend} className="flex flex-wrap justify-start -m-2"/>
      </div>
    </div>
  );
}

export default PieChart;